import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Curso } from 'src/app/models/curso';
import { Leccion } from 'src/app/models/leccion';
import { Tarea } from 'src/app/models/tarea';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-cursos',
  templateUrl: './menu-cursos.component.html',
  styleUrls: ['./menu-cursos.component.css']
})
export class MenuCursosComponent implements OnInit {

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage, public confirmationService: ConfirmationService) { }
  public completed: boolean
  public tareasList: Tarea[];
  public cursosList: Curso[];
  public leccionesList: Leccion[];
  ngOnInit(): void {
    this.crudsService.getCursos().subscribe((res: any) => {
      this.cursosList = res
    })
  }

  file: File;
  onFileSelect(event) {
   if (event.target.files.length > 0) {
     this.file = event.target.files[0];
   }
 }

  uploadImage() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectCurso.imagen = url
      })
    });  
  }

  curso: Curso;
  tareas: Array<Tarea> = new Array();
  arrayL: Leccion[] = [];
  lecciones: Array<Leccion> = new Array();
  guias: Array<Tarea> = new Array();
  procesaPropagar(mensaje: Curso) {
    if(this.array.length == 0){
      this.curso = mensaje
      this.tareas = this.curso.tareas
      this.lecciones = this.curso.lecciones
      this.guias = this.curso.agenda
      if(this.tareas != undefined){
        let claves = Object.keys(this.tareas);
        for(let i=0; i< claves.length; i++){
          let clave = claves[i];
          this.array.push(this.tareas[clave])
        }
      }
      if(this.lecciones != undefined){
        let clavesl = Object.keys(this.lecciones);
        for(let i=0; i< clavesl.length; i++){
          let clave = clavesl[i];
          this.arrayL.push(this.lecciones[clave])
        }
      }
      if(this.guias != undefined){
        let clavesg = Object.keys(this.guias);
        for(let i=0; i< clavesg.length; i++){
          let clave = clavesg[i];
          this.arrayg.push(this.guias[clave])
        }
      }
    }
  }

  reset(){
    document.getElementById("errortareas").innerHTML = ""
    document.getElementById("mensajeCursos").innerHTML = ""
    document.getElementById("successpdf").innerHTML = ""
    document.getElementById("errorpdf").innerHTML = ""
    this.crudsService.selectCurso = new Curso();
    this.array = []
    this.arrayL = []
    this.arrayg = [];
    this.nuevatarea = ""
    this.pdf = ""
    this.nuevaleccion = ""
    this.pdfleccion = ""
    this.nuevopdfguia = ""
    this.pdfguia = ""
  }

  onSubmit(cursosForm: NgForm){
    if(cursosForm.value.$key == null){
     this.crudsService.insertCurso(cursosForm.value, this.array, this.arrayL, this.arrayg);
     this.resetForm(cursosForm);
     this.reset();
    }
    this.crudsService.updateCurso(cursosForm.value, this.array, this.arrayL, this.arrayg);
    this.resetForm(cursosForm);
    this.reset();
    document.getElementById("mensajeCursos").innerHTML = "Curso actualizado correctamente"
  }
  resetForm(cursosForm?: NgForm){
    if(cursosForm != null){
      cursosForm.reset();
      this.crudsService.selectCurso = new Curso();
    }
  }


  eliminarTarea(nombre: any){
    document.getElementById("errortareas").innerHTML = ""
    document.getElementById("successtareas").innerHTML = ""
    this.confirmationService.confirm({
      message: 'Estas seguro de que quieres eliminar la tarea '+ nombre.nombre + "?",
      accept: () => {
        let index = this.array.indexOf(nombre)
        this.array.splice(index, 1)
        this.crudsService.selectCurso.tareas = this.array
        this.crudsService.updateCurso(this.crudsService.selectCurso, this.array, this.arrayL, this.arrayg)
        document.getElementById("successtareas").innerHTML = "Tarea eliminada correctamente"
      }
  });
  }

  eliminarPdfGuia(nombre: any){
    document.getElementById("errorpdf").innerHTML = ""
    document.getElementById("successpdf").innerHTML = ""
    this.confirmationService.confirm({
      message: 'Estas seguro de que quieres eliminar el pdf '+ nombre.nombre + "?",
      accept: () => {
        let index = this.arrayg.indexOf(nombre)
        this.arrayg.splice(index, 1)
        this.crudsService.selectCurso.agenda = this.arrayg
        this.crudsService.updateCurso(this.crudsService.selectCurso, this.array, this.arrayL, this.arrayg)
        document.getElementById("successpdf").innerHTML = "Pdf eliminada correctamente"
      }
  });
  }

  eliminarLeccion(nombre: any){
    document.getElementById("errorleccion").innerHTML = ""
    document.getElementById("successleccion").innerHTML = ""
    this.confirmationService.confirm({
      message: 'Estas seguro de que quieres eliminar la leccion '+ nombre.nombre + "?",
      accept: () => {
        let index = this.arrayL.indexOf(nombre)
        this.arrayL.splice(index, 1)
        this.crudsService.selectCurso.lecciones = this.arrayL
        this.crudsService.updateCurso(this.crudsService.selectCurso, this.array, this.arrayL, this.arrayg)
        document.getElementById("successleccion").innerHTML = "Leccion eliminada correctamente"
      }
  });
  }

  pdfguia: string;
  pdf: string;
  nuevatarea: string;
  nuevopdfguia: string;
  tareacompleted: boolean;
  pdfguiacompleted: boolean;
  pdfleccion: string;
  nuevaleccion: string;
  leccioncompleted: boolean;
  

  uploadPDF() {
    this.tareacompleted = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.tareacompleted = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.pdf = url
      })
    });
  }


  uploadPDFL() {
    this.leccioncompleted = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.leccioncompleted = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.pdfleccion = url
      })
    });
  }

  agendaCompleta: boolean;
  uploadPDFAgenda() {
    this.agendaCompleta = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.agendaCompleta = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.pdfguia = url
      })
    });
  }

  array: Tarea[] = [];
  anadirTarea(){
    var anadirTareaNueva = new Tarea()   
     if(this.nuevatarea != undefined && this.tareacompleted){
      
      anadirTareaNueva.nombre = this.nuevatarea;
      anadirTareaNueva.archivo = this.pdf
 
      this.array.push(anadirTareaNueva)
      this.nuevatarea = ""
      this.pdf = ""
     }
     this.tareacompleted = false
  }

  arrayg: Tarea[] = []
  anadirPdfGuia(){
    var anadirPdfGuia = new Tarea()   
     if(this.nuevopdfguia != undefined && this.agendaCompleta){
      
      anadirPdfGuia.nombre = this.nuevopdfguia;
      anadirPdfGuia.archivo = this.pdfguia
 
      this.arrayg.push(anadirPdfGuia)
      this.nuevopdfguia = ""
      this.pdfguia = ""
     }
     this.agendaCompleta = false
  }


  anadirLeccionNueva: Leccion = new Leccion();
  video: string;
  anadirLeccion(){
    var anadirLeccionNueva = new Leccion()   
     if(this.nuevaleccion != undefined && this.leccioncompleted){
      
      anadirLeccionNueva.nombre = this.nuevaleccion;
      anadirLeccionNueva.archivo = this.pdfleccion;
      if(this.video != undefined){
        anadirLeccionNueva.video = this.video;
      }
      this.arrayL.push(anadirLeccionNueva)
      this.nuevaleccion = ""
      this.pdfleccion = ""
      this.video = ""
     }
     this.leccioncompleted = false
  }

 

}
