<p>menu-usuarios works!</p>


<!-- ESTE ES EL MENU DE NOTICIAS -->
<div style="height: 100%;">
    
    <div class="container-fluid" style="background:#E1F1F5;height: 100%;padding:0;">
       
            <app-header-admin></app-header-admin>
            <div style="padding-left: 5%;padding-right: 5%;padding-top: 5%;">  
            <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
            <p style="font-size: 20px;">Menu para añadir o editar usuarios</p>
            <div class="row">
                <div class="col-4" style="padding-top: 1%;">
                    <form #noticiasForm="ngForm" (ngSubmit)="onSubmit(noticiasForm)">
                        <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudService.selectNoticia.$key">
                        <!-- AQUI SE ESCRIBE EL TITULO -->
                        <input name="usuario" 
                        type="text" 
                        class="inputclass" 
                        #name="ngModel" 
                        [(ngModel)]="crudService.selectNoticia.titulo"
                        placeholder="Nombre de usuario (email)"
                        >

                        <input name="password" 
                        type="password" 
                        class="inputclass" 
                        #name="ngModel" 
                        [(ngModel)]="crudService.selectNoticia.titulo"
                        placeholder="Contraseña"
                        >

                        <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                            Agregar
                        </button>
                        <button class="btn btn-secondary" type="reset"(click)="resetForm(noticiasForm)">
                            Limpiar
                        </button>
                    </form>
                </div>
                <div class="col-6" style="background: white;">
                    
                    <app-lista-usuarios></app-lista-usuarios>
                </div>
                
                
            </div>
        </div>
        
    </div>
</div>

