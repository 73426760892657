import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { ProyectoCatorce } from 'src/app/models/proyectocatorce';
import { ProyectoSiete } from 'src/app/models/proyectosiete';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-programas',
  templateUrl: './menu-programas.component.html',
  styleUrls: ['./menu-programas.component.css']
})
export class MenuProgramasComponent implements OnInit {

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
  }

  file: File;
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

  public completed: boolean;
  uploadPDF() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectProyectoCatorce.direccion = url
      })
    });
  }

  public completedos: boolean
  uploadPDFDos() {
    this.completedos = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completedos = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectProyectoSiete.direccion = url
      })
    });
  }

  public completetres: boolean
  uploadPDFTres() {
    this.completetres = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completetres = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectProyectoVtres.direccion = url
      })
    });
  }

  onSubmit(proyectoCatorceForm: NgForm){
    if(proyectoCatorceForm.value.$key == null)
      this.crudsService.insertProyectoCatorce(proyectoCatorceForm.value);
    else
      this.crudsService.updateProyectoCatorce(proyectoCatorceForm.value);
      this.resetForm(proyectoCatorceForm);
  }
  resetForm(proyectoCatorceForm?: NgForm){
    if(proyectoCatorceForm != null){
      proyectoCatorceForm.reset();
      this.crudsService.selectProyectoCatorce = new ProyectoCatorce();
    }
  }

  onSubmitDos(proyectoSieteForm: NgForm){
    if(proyectoSieteForm.value.$key == null)
      this.crudsService.insertProyectoSiete(proyectoSieteForm.value);
    else
      this.crudsService.updateProyectoSiete(proyectoSieteForm.value);
      this.resetForm(proyectoSieteForm);
  }
  resetFormDos(proyectoSieteForm?: NgForm){
    if(proyectoSieteForm != null){
      proyectoSieteForm.reset();
      this.crudsService.selectProyectoSiete = new ProyectoSiete();
    }
  }

  onSubmitTres(proyectoVtresForm: NgForm){
    if(proyectoVtresForm.value.$key == null)
      this.crudsService.insertProyectoVtres(proyectoVtresForm.value);
    else
      this.crudsService.updateProyectoVtres(proyectoVtresForm.value);
      this.resetForm(proyectoVtresForm);
  }
  resetFormTres(proyectoVtresForm?: NgForm){
    if(proyectoVtresForm != null){
      proyectoVtresForm.reset();
      this.crudsService.selectProyectoVtres = new ProyectoSiete();
    }
  }

}
