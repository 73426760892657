import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-servicios-perfil',
  templateUrl: './lista-servicios-perfil.component.html',
  styleUrls: ['./lista-servicios-perfil.component.css']
})
export class ListaServiciosPerfilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
