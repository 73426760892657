import { not } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database'
import {Noticia} from '../models/noticia'
import { Pueblo } from '../models/pueblo';
import { Servicio } from '../models/servicio';
import { Junta } from '../models/junta';
import { Contratado } from '../models/contratado';
import { Curso } from '../models/curso';
import { Leccion } from '../models/leccion';
import { Tarea } from '../models/tarea';
import { ProyectoCatorce } from '../models/proyectocatorce';
import { ProyectoSiete } from '../models/proyectosiete';
import { Oferta } from '../models/oferta';
import { Examen } from '../models/examen';
import { Resultado } from '../models/resultado';
import { Tiempo } from '../models/tiempo';
import { ProyectoVtres } from '../models/proyectovtres';

@Injectable({
  providedIn: 'root'
})
export class CrudsService {

  examenesList: AngularFireList<any>;
  noticiasList: AngularFireList<any>;
  pueblosList: AngularFireList<any>;
  cursosList: AngularFireList<any>;
  serviciosList: AngularFireList<any>;
  juntaList: AngularFireList<any>;
  contratadosList: AngularFireList<any>;
  pruebaList: AngularFireList<any>;
  proyectocatorceList: AngularFireList<any>;
  proyectoveintitresList: AngularFireList<any>;
  proyectosieteList: AngularFireList<any>;
  ofertasList: AngularFireList<any>;
  resultadosList: AngularFireList<any>;
  tiemposList: AngularFireList<any>;

  
  selectNoticia: Noticia = new Noticia();
  selectPueblo: Pueblo = new Pueblo();
  selectJunta: Junta = new Junta();
  selectServicio: Servicio = new Servicio();
  selectContratado: Contratado = new Contratado();
  selectCurso: Curso = new Curso();
  selectProyectoCatorce: ProyectoCatorce = new ProyectoCatorce();
  selectProyectoVtres: ProyectoVtres = new ProyectoVtres();
  selectProyectoSiete: ProyectoSiete = new ProyectoSiete();
  selectOferta: Oferta = new Oferta();
  selectExamen: Examen = new Examen();
  selectResultado: Resultado = new Resultado();

  selectTiempo: Tiempo = new Tiempo();
  
  noticias: Noticia[];




  
  constructor(private firebase: AngularFireDatabase) { }

  getNoticias(){
    return this.noticiasList = this.firebase.list('noticias')
  }

  getTiemposO(){
    return this.tiemposList = this.firebase.list('tiempo')
  }

  getContratadosO(){
    return this.contratadosList = this.firebase.list('contratados')
  }

  getResultadosO(){
    return this.resultadosList = this.firebase.list('resultados')
  }

  getCursosO(){
    return this.cursosList = this.firebase.list('cursos')
  }

  getOfertasO(){
    return this.ofertasList = this.firebase.list('ofertas')
  }

  getProyectoCatorceO(){
    return this.proyectocatorceList = this.firebase.list('proyectocatorce')
  }

  getProyectoVtresO(){
    return this.proyectocatorceList = this.firebase.list('proyectovtres')
  }

  getProyectoSieteO(){
    return this.proyectosieteList = this.firebase.list('proyectosiete')
  }

  getJuntaO(){
    return this.juntaList = this.firebase.list('junta')
  }

  getPueblosO(){
    return this.pueblosList = this.firebase.list('pueblos')
  }

  getExamenesO(){
    return this.examenesList = this.firebase.list('examenes')
  }

  getServiciosO(){
    return this.serviciosList = this.firebase.list('servicios')
  }

  getServicios(){
    this.serviciosList = this.firebase.list('servicios')
    return this.serviciosList.valueChanges();
  }

  getNoticiasO(){
    this.noticiasList = this.firebase.list('noticias')
    return this.noticiasList.valueChanges();
  }

  getCursos(){
    this.cursosList = this.firebase.list('cursos')
    return this.cursosList.valueChanges();
  }

  getTiempos(){
    this.tiemposList = this.firebase.list('tiempo')
    return this.tiemposList.valueChanges();
  }

  getResultados(){
    this.resultadosList = this.firebase.list('resultados')
    return this.resultadosList.valueChanges();
  }

  getOfertas(){
    this.ofertasList = this.firebase.list('ofertas')
    return this.ofertasList.valueChanges();
  }

  getPueblos(){
    this.noticiasList = this.firebase.list('pueblos')
    return this.noticiasList.valueChanges();
  }
  getExamenes(){
    this.examenesList= this.firebase.list('examenes')
    return this.examenesList.valueChanges();
  }

  getProyectoCatorce(){
    this.proyectocatorceList= this.firebase.list('proyectocatorce')
    return this.proyectocatorceList.valueChanges();
  }

  getProyectoVtres(){
    this.proyectocatorceList= this.firebase.list('proyectovtres')
    return this.proyectocatorceList.valueChanges();
  }

  getProyectoSiete(){
    this.proyectosieteList= this.firebase.list('proyectosiete')
    return this.proyectosieteList.valueChanges();
  }

  getJunta(){
    this.juntaList= this.firebase.list('junta')
    return this.juntaList.valueChanges();
  }

  getContratados(){
    this.contratadosList= this.firebase.list('contratados')
    return this.contratadosList.valueChanges();
  }

  insertNoticia(noticia: Noticia){
    this.noticiasList.push({
      titulo: noticia.titulo,
      imagen: noticia.imagen,
      url: noticia.url
    });

  }

  insertServicio(servicio: Servicio){
    this.serviciosList.push({
      nombre: servicio.nombre,
      direccion: servicio.direccion
    });

  }

  insertTiempo(tiempo: Tiempo){
    this.tiemposList.push({
      alumno: tiempo.alumno,
      tiempo: tiempo.tiempo,
      curso: tiempo.curso
    });

  }

  insertResultado(resultado: Resultado){
    this.resultadosList.push({
      alumno: resultado.alumno,
      resultado: resultado.resultado,
      examen: resultado.examen,
      curso: resultado.curso,
      fecha: resultado.fecha
    });

  }

  insertOferta(oferta: Oferta){
    this.ofertasList.push({
      nombre: oferta.nombre,
      direccion: oferta.direccion
    });

  }

  
  insertContratado(contratado: Contratado){
    this.contratadosList.push({
      nombre: contratado.nombre,
      direccion: contratado.direccion
    });

  }

  insertProyectoCatorce(proyecto: ProyectoCatorce){
    this.proyectocatorceList.push({
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });

  }

  insertProyectoVtres(proyecto: ProyectoVtres){
    this.proyectocatorceList.push({
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });

  }

  insertProyectoSiete(proyecto: ProyectoSiete){
    this.proyectosieteList.push({
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });

  }

  insertExamen(examen: Examen){
    this.examenesList.push({
      id: examen.id,
      nombre: examen.nombre,
      preguntas: examen.preguntas
    });

  }

  insertJunta(junta: Junta){
    this.juntaList.push({
      asociacion: junta.asociacion,
      cargo: junta.cargo,
      foto:junta.foto,
      representacion:junta.representacion,
      titular:junta.titular
    });

  }

  insertCurso(curso: Curso, tareas: Array<Tarea>, lecciones: Array<Leccion>, agenda: Array<Tarea>){
    console.log(curso)
    this.cursosList.push({
      agenda: agenda,
      emailprofesor: curso.emailprofesor,
      id: curso.id,
      imagen: curso.imagen,
      lecciones: lecciones,
      nombreprofesor: curso.nombreprofesor,
      tareas: tareas
    });

  }


  insertPueblo(pueblo: Pueblo){
    this.pueblosList.push({
      PUEBLO: pueblo.PUEBLO,
      ALOJAMIENTO: pueblo.ALOJAMIENTO,
      AYUNTAMIENTO: pueblo.AYUNTAMIENTO,
      'ASOCIACIONES Y OTROS COLECTIVOS': pueblo['ASOCIACIONES Y OTROS COLECTIVOS'],
      'AUTOBÚS O TREN': pueblo['AUTOBÚS O TREN'],
      'BANCOS Y CAJAS': pueblo['BANCOS Y CAJAS'],
      'BARES Y RESTAURANTES': pueblo['BARES Y RESTAURANTES'],
      'CENTRO DE SALUD': pueblo['CENTRO DE SALUD'],
      'CENTROS EDUCATIVOS': pueblo['CENTROS EDUCATIVOS'],
      COOPERATIVAS: pueblo.COOPERATIVAS,
      FARMACIAS: pueblo.FARMACIAS,
      FIESTAS: pueblo.FIESTAS,
      FOTO: pueblo.FOTO,
      GASOLINERAS: pueblo.GASOLINERAS,
      GUARDERIAS: pueblo.GUARDERIAS,
      'GUARDIA CIVIL': pueblo['GUARDIA CIVIL'],
      'LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL': pueblo['LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL'],
    MUSEOS: pueblo.MUSEOS,
    NEGOCIOS: pueblo.NEGOCIOS,
    OTROS: pueblo.OTROS,
    RESIDENCIAS: pueblo.RESIDENCIAS,
    VETERINARIOS: pueblo.VETERINARIOS,
    'ZONAS DE OCIO Y DESCANSO': pueblo['ZONAS DE OCIO Y DESCANSO'],
    'ZONAS DEPORTIVAS': pueblo['ZONAS DEPORTIVAS'],
  

    });
    

  }

  updateNoticia(noticia: Noticia){
    this.noticiasList.update(noticia.$key,{
      titulo: noticia.titulo,
      imagen: noticia.imagen,
      url: noticia.url
    });
  }

  updateExamen(examen: Examen){
    this.examenesList.update(examen.$key,{
      id: examen.id,
      nombre: examen.nombre,
      preguntas: examen.preguntas
    });
  }

  updateServicio(servicio: Servicio){
    this.serviciosList.update(servicio.$key,{
      nombre: servicio.nombre,
      direccion: servicio.direccion
    });
  }

  updateTiempo(tiempo: Tiempo){
    this.tiemposList.update(tiempo.$key,{
      alumno: tiempo.alumno,
      tiempo: tiempo.tiempo
    });
  }

  updateOferta(oferta: Oferta){
    this.ofertasList.update(oferta.$key,{
      nombre: oferta.nombre,
      direccion: oferta.direccion
    });
  }

  updateProyectoCatorce(proyecto: ProyectoCatorce){
    this.proyectocatorceList.update(proyecto.$key,{
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });
  }

  updateProyectoVtres(proyecto: ProyectoVtres){
    this.proyectocatorceList.update(proyecto.$key,{
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });
  }

  updateProyectoSiete(proyecto: ProyectoSiete){
    this.proyectosieteList.update(proyecto.$key,{
      nombre: proyecto.nombre,
      direccion: proyecto.direccion
    });
  }

  updateContratado(contratado: Contratado){
    this.contratadosList.update(contratado.$key,{
      nombre: contratado.nombre,
      direccion: contratado.direccion
    });
  }

  updateCurso(curso: Curso, tareas: Array<Tarea>, lecciones: Array<Leccion>, guia: Array<Tarea>){
    this.cursosList.update(curso.$key,{
      agenda: guia,
      emailprofesor: curso.emailprofesor,
      id: curso.id,
      imagen: curso.imagen,
      lecciones: lecciones,
      nombreprofesor: curso.nombreprofesor,
      tareas: tareas
    });

  }


  updateJunta(junta: Junta){
    this.juntaList.update(junta.$key,{
      asociacion: junta.asociacion,
      cargo: junta.cargo,
      foto:junta.foto,
      representacion:junta.representacion,
      titular:junta.titular
    });
  }

  updatePueblo(pueblo: Pueblo){
    this.pueblosList.update(pueblo.$key,{
      PUEBLO: pueblo.PUEBLO,
      ALOJAMIENTO: pueblo.ALOJAMIENTO,
      AYUNTAMIENTO: pueblo.AYUNTAMIENTO,
      'ASOCIACIONES Y OTROS COLECTIVOS': pueblo['ASOCIACIONES Y OTROS COLECTIVOS'],
      'AUTOBÚS O TREN': pueblo['AUTOBÚS O TREN'],
      'BANCOS Y CAJAS': pueblo['BANCOS Y CAJAS'],
      'BARES Y RESTAURANTES': pueblo['BARES Y RESTAURANTES'],
      'CENTRO DE SALUD': pueblo['CENTRO DE SALUD'],
      'CENTROS EDUCATIVOS': pueblo['CENTROS EDUCATIVOS'],
      COOPERATIVAS: pueblo.COOPERATIVAS,
      FARMACIAS: pueblo.FARMACIAS,
      FIESTAS: pueblo.FIESTAS,
      FOTO: pueblo.FOTO,
      GASOLINERAS: pueblo.GASOLINERAS,
      GUARDERIAS: pueblo.GUARDERIAS,
      'GUARDIA CIVIL': pueblo['GUARDIA CIVIL'],
      'LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL': pueblo['LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL'],
    MUSEOS: pueblo.MUSEOS,
    NEGOCIOS: pueblo.NEGOCIOS,
    OTROS: pueblo.OTROS,
    RESIDENCIAS: pueblo.RESIDENCIAS,
    VETERINARIOS: pueblo.VETERINARIOS,
    'ZONAS DE OCIO Y DESCANSO': pueblo['ZONAS DE OCIO Y DESCANSO'],
    'ZONAS DEPORTIVAS': pueblo['ZONAS DEPORTIVAS']
    });
  }

  deleteNoticia($key: string){
    this.noticiasList.remove($key)
  }

  deletePueblo($key: string){
    this.pueblosList.remove($key)
  }

  deleteServicio($key: string){
    this.serviciosList.remove($key)
  }

  deleteJunta($key: string){
    this.juntaList.remove($key)
  }

  deleteContratado($key: string){
    this.contratadosList.remove($key)
  }

  deleteCurso($key: string){
    this.cursosList.remove($key)
  }

  deleteProyectoCatorce($key: string){
    this.proyectocatorceList.remove($key)
  }

  deleteProyectoVtres($key: string){
    this.proyectocatorceList.remove($key)
  }

  deleteProyectoSiete($key: string){
    this.proyectosieteList.remove($key)
  }

  deleteOferta($key: string){
    this.ofertasList.remove($key)
  }

  deleteExamen($key: string){
    this.examenesList.remove($key)
  }

  deleteTiempo($key: string){
    this.tiemposList.remove($key)
  }

  
}
