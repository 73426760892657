import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { Junta } from 'src/app/models/junta';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-junta',
  templateUrl: './menu-junta.component.html',
  styleUrls: ['./menu-junta.component.css']
})
export class MenuJuntaComponent implements OnInit {

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }
  public completed: boolean
  ngOnInit(): void {

    this.crudsService.getJunta();
    this.resetForm()
  }

  
 file: File;
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

  uploadImage() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectJunta.foto = url
      })
    });
    
  }

  onSubmit(juntaForm: NgForm){
    if(juntaForm.value.$key == null)
      this.crudsService.insertJunta(juntaForm.value);
    else
      this.crudsService.updateJunta(juntaForm.value);
      this.resetForm(juntaForm);
  }
  resetForm(juntaForm?: NgForm){
    if(juntaForm != null){
      juntaForm.reset();
      this.crudsService.selectJunta = new Junta();
    }
  }

}
