import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';
import { JsonserviceService } from 'src/app/services/jsonservice.service';

@Component({
  selector: 'app-buscador-pueblos',
  templateUrl: './buscador-pueblos.component.html',
  styleUrls: ['./buscador-pueblos.component.css']
})
export class BuscadorPueblosComponent implements OnInit {

  constructor(private json: JsonserviceService, private crudsService: CrudsService) { }
  filterDatos = '';
  datospueblos: any[]
  isEnabledp: boolean = false;
  isEnabledc: boolean = false;
  isEnableds: boolean = false;
  opcionSeleccionado: string ;
  verSeleccion: string = ""
  nombres = [];
  datosfiltrados = [];
  dict = []; // create an empty array


  ngOnInit(): void {
  
    this.crudsService.getPueblos().subscribe((res: any) => {
      this.datospueblos = res
    })
  }

  locateIndiv(array:any[], data: any){
    this.nombres = []
    
        
    let search : any = data;

    switch (search) {
        case "Gasolineras":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                  this.dict.push({key: array[i].PUEBLO, value: array[i].GASOLINERAS})
            }
          }
          console.log(this.dict)
            
            break;
        case "Fiestas":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].FIESTAS})
              }
            }
            console.log(this.dict)
            break;
          case "Ayuntamiento":
            this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].AYUNTAMIENTO})
              }
            }
            console.log(this.dict)
            break;
        case "Farmacias":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].FARMACIAS})
              }
            }
            console.log(this.dict)
            break;
        case "Lugares":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].LUGARES})
              }
            }
            console.log(this.dict)
            break;
        case "Guardia civil":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].GUARDIACIVIL})
              }
            }
            console.log(this.dict)
            break;
        case "Asociaciones":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].ASOCIACIONES})
              }
            }
            console.log(this.dict)
            break;
        case "Bares":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].BARES})
              }
            }
            console.log(this.dict)
            break;
        case "Cooperativas":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].COOPERATIVAS})
              }
            }
            console.log(this.dict)
            break;
        case "Zonas deportivas":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].ZONASDEPORTIVAS})
              }
            }
            console.log(this.dict)
            break;
         case "Bancos y cajas":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].BANCOS})
              }
            }
            console.log(this.dict)
            break;
        case "Centro de salud":
          this.dict = [];
            for(var i = 0; i < array.length; i++){
              if(array[i].PUEBLO != ""){
                this.dict.push({key: array[i].PUEBLO, value: array[i].CENTRODESALUD})
              }
            }
            console.log(this.dict)
            break;
        default:
            console.log("No such day exists!");
            break;
}
    console.log(this.nombres, data.toUpperCase())
  }

  filtrarporNombre(nombre: string){

  }
  showCentros(){
    document.getElementById("tabla1").style.display = "none"
    document.getElementById("tabla2").style.display = "block"
  }
  capturar(){
    this.verSeleccion = this.opcionSeleccionado;
    this.generarTabla(this.verSeleccion)
    
  }

  generarTabla(data: any){
    document.getElementById("tabla1").style.display = "none"
    document.getElementById("tablaayuntamiento").style.display = "block"
    document.getElementById("data").innerHTML = data

    this.locateIndiv(this.datospueblos, data)
    
  }
  come(){
    document.getElementById("tablaayuntamiento").style.display = "none"
    document.getElementById("tabla1").style.display = "block                                "
  }
  
}
