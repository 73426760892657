<div id = "sliderPueblo">
    <div class = "container" id = "mh" style="background:white;MARGIN-TOP:5%;border-radius: 4px;max-width: 800px;">
        <div class = "row">
            <div class = "col-lg-12" style="font-size: 40px;padding:2%">
                <i (click)="closePopUp()"style= "float: right;cursor: pointer;" class="far fa-times-circle"></i>
            </div>
            <div class = "col-lg-12">
                <div id = "content" style="overflow-y: scroll;height:25em">
                    <p id = "puebloname" style="color:#394F69;font-weight: 400;font-size: 40px;"></p>
                    <p style="font-weight: 600;">Descripción</p>
                    <p id = "pueblodesc"></p>
                    <p style="font-weight: 600;">Información</p>
                    <p id = "puebloinfo"></p>
                    <p style="font-weight: 600;">Galeria</p>
                    <div id = "imgs"></div>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>
<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Los pueblos</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class = "container" style="padding: 2%;padding-top: 4%;"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
    <div class = "row">
        <div *ngFor="let pueblo of pueblos" (click)="intoPopUp(pueblo)" id = "pueblotitle" class = "col-lg-4">
            {{pueblo}}
        </div>
    </div>
</div>
<app-footer></app-footer>