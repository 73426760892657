<div id = "loader2" class = "loader2">
    <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>  
    
  </div>
<app-header></app-header>
<app-carrusel></app-carrusel>
<app-informacion></app-informacion>
<app-destacados></app-destacados>
<div class="container-fluid" style="background:#F6F7F9;padding-top: 2.5%;padding-bottom: 2.5%;">
  <div class = "container" style = "background:#F6F7F9;padding-top: 5em;padding-bottom: 5em;">
    <div class = "row" data-aos="fade-up" data-aos-duration="800">
      <div *ngFor="let noticia of this.imagenes" class = "col-md-4" style = "height:25em;">
        <div style="height:100%;background:white;width:100%;cursor:pointer">
          <div (click)="goTo(noticia.url)" style="height:80%;background-image: url('../../../assets/noticias/04-21.png');background-size: 100% 150%;"></div>
          <div style="height:20%;background:#233142;color:white;display: flex;align-items: center;padding: 1em;">
            <h3>{{noticia.titulo}}</h3>
          </div>
        </div>
      </div>  
    </div>
  </div>
</div>
<app-footer></app-footer>
