<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12" style="display:flex;align-items:center;justify-content: center;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Zona de usuarios</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class="container">
    <br>
    <div class="row" style="background:#1c1c1c;color:white">
        <div id = "agenda" (click)="agendacontent('Guía del curso')" class="col-md-2">Guía del curso</div>
        <div (click)="tareascontent('Tareas')" class="col-md-2">Tareas</div>
        <div (click)="leccionescontent('Lecciones')" class="col-md-2">Lecciones</div>
        <div (click)="examenescontent('Examenes')" class="col-md-2">Examenes</div>
        <div (click)="resultadoscontent('Resultados')" class="col-md-2">Resultados</div>
        <div (click)="atalumnocontent('At Alumno')" class="col-md-2">At Alumno</div>
    </div>
    
    <br>
</div>
<div class="container" style="background:whitesmoke;min-height:10em;padding-bottom: 1em;">
    <div class="row" style="display: flex;align-items: center;justify-content: center;">
      <span style="font-weight: bold;">{{this.listares[1]}}</span>
    </div>
    
   


    <div *ngIf="agendab" class="row" style="padding-left: 5%;padding-right: 5%;margin-top: 1%;">
        <div (click)="goTo(item.archivo)" class="col-md-3"  *ngFor="let item of listagenda"  style="background:white;display: flex;align-items: center;font-size: 35px;cursor:pointer;padding-top:0.5%;padding-left: 0.5%;padding-bottom: 0.5%;margin-right: 0.5em;">
            <i style="color:#a73535" class="fas fa-file-pdf"></i>
            <span style="font-size: 15px;">&nbsp;&nbsp;{{item.nombre}}</span>
        </div>
    </div>

    <div *ngIf="tareasb" class="row" style="padding-left: 5%;padding-right: 5%;margin-top: 1%;">
        <div *ngFor="let item of listareas" class="col-md-3" style="cursor:pointer">
            <div (click)="goTo(item.archivo)" style="background:white;display: flex;align-items: center;font-size: 35px;cursor:pointer;padding-top:2%;padding-left: 2%;padding-bottom: 2%;">
                <i style="color:#a73535" class="fas fa-file-pdf"></i>
                <span style="font-size: 15px;">&nbsp;&nbsp;{{item.nombre}}</span>
            </div>
        </div>
        <div class="col-md-2" (click)="openEmail()" style="display: flex;align-items: center;justify-content: center;background:rgb(46, 46, 46);color:white;border:2px solid white">
            <span>Envia tu trabajo</span>
        </div>
    </div>

    <div *ngIf="leccionesb" class="row" style="padding-left: 5%;padding-right: 5%;margin-top: 1%;">
        <div *ngFor="let item of listalecciones" class="col-md-3" >
          
            <div (click)="goTo(item.archivo)" style="background:white;display: flex;align-items: center;font-size: 35px;cursor:pointer;padding-top:2%;padding-left: 2%;padding-bottom: 2%;">
                <i style="color:#a73535" class="fas fa-file-pdf"></i>
                <span style="font-size: 15px;">&nbsp;&nbsp;{{item.nombre}}</span>
            </div>
            <div style="padding-left: 2%;padding-top: 2%;">
                <span (click)="goTo(item.video)" style = "color:#394F69;cursor:pointer;font-weight: 600;" *ngIf = "item.video != undefined" href = "{{item.video}}">Ver video</span>
            </div>
        </div>
    </div>

    <div *ngIf="examenesb" class="row" style="padding-left: 5%;padding-right: 5%;margin-top: 1%;">
        <div *ngFor="let item of listaexamenes" class="col-md-3" style="height:3.2em;cursor:pointer">
           
            <div routerLink = "/examen/{{this.id}}/{{item.nombre}}" style="background:white;display: flex;align-items: center;font-size: 35px;cursor:pointer;padding-top:2%;padding-left: 2%;padding-bottom: 2%;">
                <i style="color:#394F69" class="fas fa-file-word"></i>
                <span style="font-size: 15px;">&nbsp;&nbsp;{{item.nombre}}</span>
            </div>
        </div>
    </div>

    <div *ngIf="resultadosb" class="row" style="padding-left: 5%;padding-right: 5%;margin-top: 1%;">
        <div style="background:white;margin-left: 1.5px;margin-bottom: 3px;" class = "col-lg-2" *ngFor="let resultado of misResultados">
            <span style="font-weight: bold;">Resultado</span><br>
            <span>Nota: {{resultado.resultado}}</span><br>
            <span>Examen: {{resultado.examen}}</span><br>
            <span>Fecha: {{resultado.fecha}}</span>
        </div>
    </div>
    
    

    
</div>
<br><br>
<app-footer></app-footer>
