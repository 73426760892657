<div class = "container-fluid" style = "background:#233142;width:100%;padding-top: 5%;">
    <div class = "container" style = "height:100%">
        <div class = "row" style="height:100%;">
            <div class = "col-md-4" style = "height:20em;">
                <div style="color:white;width:100%;font-size:25px;display: flex;align-items: center;justify-content: center;" data-aos="fade-down" data-aos-duration="800">
                    <i STYLE = "color:#0AC775;margin-right: 0.5em;" class="fas fa-money-bill"></i> SUBVENCIONES
                </div>
                <div style = "padding-left:1em;width:100%;padding-top: 1em;color:white" data-aos="fade-right" data-aos-duration="800">
                    ADESIMAN en este apartado realiza la confección de solicitudes, vigila su coordinación con los proyectos técnicos y las licitaciones y 
                    asegura la correcta justificación documental y técnica para hacer factible la obtención de la ayuda y la correcta ejecución material
                </div>
            </div>
            <div class = "col-md-4" style = "height:20em;">
                <div style="color:white;width:100%;font-size:25px;display: flex;align-items: center;justify-content: center;" data-aos="fade-down" data-aos-duration="800">
                    <i STYLE = "color:#0AC775;margin-right: 0.5em;"class="fas fa-graduation-cap"></i>FORMACION
                </div>
                <div style = "padding-left:1em;width:100%;padding-top: 1em;color:white" data-aos="fade-up" data-aos-duration="800">
                    ADESIMAN promociona e imparte cursos de formación dentro de las tipologías on line y presenciales encauzados al desarrollo rural y contenidos innovadores como el medioambiente, 
                    la Unión Europea, la eficiencia energética, la implantación de la economía circular, el desarrollo de pueblos inteligentes
                </div>
            </div>
            <div class = "col-md-4" style = "height:20em;">
                <div style="color:white;width:100%;font-size:25px;display: flex;align-items: center;justify-content: center;" data-aos="fade-down" data-aos-duration="800">
                    <i STYLE = "color:#0AC775;margin-right: 0.5em;" class="fas fa-concierge-bell"></i> SERVICIOS
                </div>
                <div style = "padding-left:1em;width:100%;padding-top: 1em;color:white" data-aos="fade-left" data-aos-duration="800">
                    ADESIMAN en este apartado realiza la confección de solicitudes, vigila su coordinación con los proyectos técnicos y las licitaciones y asegura la correcta 
                    justificación documental y técnica para hacer factible la obtención de la ayuda y la correcta ejecución material
                </div>
            </div>
        </div>
    </div>
    

</div>