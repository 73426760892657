     
        <table class="table table-sm table-hover">
            
            <tbody>
                <div class = "col-lg-12">
                    
                    <span style="font-size: 20px;">Lista de pueblos</span><span style="float: right;">Pueblos totales: {{totalItems}}</span>
                    <div id = "content" style="overflow-y: scroll;max-height:250px;width: 100%;">
                        <div id = "divcontent" *ngFor="let pueblo of pueblosList">
                            <td style="">
                                <span>Nombre de la noticia</span> - {{pueblo.PUEBLO}} <br> 
                                <span> Direccion de la imagen </span> - {{pueblo.ALOJAMIENTO}} <br>
                                <span> Direccion del PDF </span>- {{pueblo['ASOCIACIONES Y OTROS COLECTIVOS']}}
                            </td>
                            
                            <button (click)="onEdit(pueblo)" style="margin-right: 1%;" class = "btn btn-secondary text-white"><i class="far fa-edit"></i></button>
                            <button (click)="onDelete(pueblo.$key, pueblo.PUEBLO)" class = "btn btn-danger text-white"><i class="far fa-trash-alt"></i></button>
                            
                        </div>
                    </div>
                    <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>


                </div>
                
            </tbody>
        </table>