<div class="container-fluid" id = "contenedorLogin">
    <div class="row">
        <div class="col-lg-12" style="padding: 1%;font-size: 30px;">
            <i (click)="closeLogin()" style="float:right" class="far fa-times-circle"></i>
        </div>
    </div>
    <div class="container-fluid" id = "interCont">
        <div class="container" id = "contenedorC">
            <div class="row">
                <div class="col-lg-6" style="background:whitesmoke;padding: 2%;">
                    <h3>Bienvenido a adesiman.com</h3>
                    <span style="display: block;font-size: 20px;font-weight: 600;">Usuarios registrados</span><br>
                    <span>Inicia sesión con tu cuenta para acceder al contenido solicitado</span>
                    <br><br>
                    
                    <div class="col-auto" style="padding: 0;width: 90%;">
                        <label class="sr-only" for="inlineFormInputGroup">Introduce tu nombre de usuario</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                                <i class="far fa-user"></i>
                            </div>
                          </div>
                          <input [(ngModel)]="auth.email" type="email" class="form-control" id="inlineFormInputGroup" placeholder="Email">
                         
                        </div>
                      </div>
                      <br>
                      <div class="col-auto" style="padding: 0;width: 90%;">
                        <label class="sr-only" for="inlineFormInputGroup">Introduce tu nombre de usuario</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                                <i class="fas fa-key"></i>
                            </div>
                          </div>
                          <input [(ngModel)]="auth.password" type="password" class="form-control" id="inlineFormInputGroup" placeholder="Contraseña">
                        </div>
                      </div>
                      <span style="display: block;">¿Has olvidado tu contraseña?</span>
                      <button (click)="auth.login()" class="botonlogin">INICIA SESION</button>
                      <p id = "errors"></p>
                      <p style = "visibility: hidden;" id = "gastly"></p>
                      <br><br>
                      <hr>
                      <div style="width:90%;text-align:center">
                        <span style="display: block;">o inicia sesión con</span>
                        <i style="font-size: 30px;margin-top: 2%;border: 1px solid black;padding: 3%;" class="fab fa-google"></i>
                      </div>
                   
                </div>
                <div class="col-lg-6" style="background:whitesmoke;padding: 2%;">
                    <h3></h3>
                    <span style="display: block;font-size: 20px;font-weight: 600;">Nuevos usuarios</span><br>
                    <button class="botonlogin" style="margin:0">CREA TU CUENTA</button>
                    <br><br>
                   
                </div>
                
            </div>
        </div>
    </div>
</div>





<div (mouseleave)="closeMenu()" id = "slide">
    <div style="height:12%;width:100%;"></div>
    <div class = "container" style="height:4em;width:100%;display: flex;align-items: center;justify-content: center;color:white;border-top:3px solid white;">
        MENU
    </div>
    <div class = "container">
        <div class = "row">
            <div routerLink="/sobre-adesiman" class = "col-lg-12" id = "itemm">
                Sobre nosotros &nbsp; <i class="far fa-address-card"></i>
            </div>
            <div (click)="goTo('https://adesiman.sedipualba.es')" class = "col-lg-12" id = "itemm">
                Sede electronica &nbsp; <i class="fa fa-outdent" aria-hidden="true"></i>
            </div>
            <div routerLink="/busca-en-el-territorio" class = "col-lg-12" id = "itemm">
                Busca en el territorio &nbsp; <i class="fas fa-search"></i>
            </div>
            <div routerLink = "/servicios" class = "col-lg-12" id = "itemm">
                Servicios &nbsp; <i class="fas fa-users-cog"></i>
            </div>
            <div routerLink = "/perfil-del-contratante" class = "col-lg-12" id = "itemm">
                Perfil del contratante &nbsp; <i class="fas fa-users"></i>
            </div>
            <div routerLink = "/ofertas-de-trabajo" class = "col-lg-12" id = "itemm">
                Ofertas de trabajo &nbsp; <i class="fa fa-briefcase" aria-hidden="true"></i>
            </div>
            <div (click)="showLogin()" class = "col-lg-12" id = "itemm">
                Inicia sesión &nbsp; <i class="fas fa-sign-in-alt"></i>
            </div>
            <div style="cursor:pointer" routerLink="/pruebas" id = "administracion" class = "administracion col-lg-12">
                Administracion &nbsp;<i class="fas fa-user-cog"></i>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" style="height:15%;background:white">
    <div class = "row" style="height: 100%;">
        <div class = "col-lg-1" style="height:100%;color: #233142;z-index: 1101;
        display: flex;align-items: center;justify-content: center;font-size: 40px;">
            <i (mouseover)="showSlide()" (click)="closeMenu()" id = "bars" class="fas fa-bars"></i>
        </div>
        <div class = "col-lg-8" style="height:100%;">
            <div class = "row" id = "rowImagenes">
                <div class = "col-lg-3"></div>
                <div class = "col-lg-3" style="height:100%;display: flex;align-items: center;justify-content: center;">
                    <img src = "../../../../assets/Adesiman.jpg" style="width:65%;height: 50%;"/>
                </div>
                <div class = "col-lg-3" style="height:100%;display: flex;align-items: center;justify-content: center;">
                    <img src = "../../../../assets/Adimmac.jpg" style="width:65%;height: 50%;"/>
                </div>
                <div class = "col-lg-3" style="height:100%;display: flex;align-items: center;justify-content: center;">
                    <img src = "../../../../assets/sedecuce.jpg" style="width:65%;height: 50%;"/>
                </div>
            </div>
        </div>
        <div class = "col-lg-3" style="height:100%;display: flex;align-items: center;justify-content: center;">
            <div style="width: 100%;display: flex;align-items: flex-end;justify-content: flex-end;">
                
                <div (click)="showLogin()" style="width:100%;height:100%;cursor: pointer;display:flex;align-items: center;justify-content: center;" id = "lgdiv">
                    <div _ngcontent-aqi-c25="" class="col-lg-5" id = "botonIniciarSesion"><i _ngcontent-aqi-c25="" class="fas fa-portrait" aria-hidden="true"></i>&nbsp; Iniciar sesion </div>
                </div>

                <div  style="width:50%;height:100%;cursor: pointer;" id = "postlogin">
                    <div style="width:100%;display: flex;align-items: center;justify-content: center;font-size: 40px;padding-bottom: 5%;color:#233142">
                        <i style="font-size: 30px;" class="fas fa-user"></i>
                    </div>
                    <div style="width:100%;text-align: center;color:#233142;margin-right: 4em;">{{this.auth.globalemail}}<br><a (click)="auth.logout()"href = "">Cerrar sesion</a></div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="container-fluid" id = "contHeader">
    <div class = "container" id = "contHeader2">
        <div class = "row" style="height: 100%;background: #394f69;color:white">
            <div class = "col-lg-3" id="ite">
                <div style="width:100%;text-align:center">
                    <div class="dropdown">
                        <button   id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Territorio adesiman  &nbsp; <i class="fas fa-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" routerLink = "/el-territorio">El territorio</a>
                          <a class="dropdown-item" routerLink = "/los-pueblos" href="#">Los pueblos</a>
                        </div>
                      </div>
                    
                </div>
            </div>
            <div class = "col-lg-2" id="ite">
                <div style="width:100%;text-align:center">
                    <div class="dropdown">
                        <button   id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Programa leader  &nbsp; <i class="fas fa-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" routerLink="/proyecto-2023-2027" >Proyecto 2023 - 2027</a>
                          <a class="dropdown-item" routerLink="/proyecto-2014-2021" >Proyecto 2014 - 2021</a>
                          <a class="dropdown-item" routerLink="/proyecto-2007-2013">Proyecto 2007 - 2013</a>
                          <a class="dropdown-item" routerLink = "/programa-proder">Programa PRODER</a>
                        </div>
                      </div>
                    
                </div>
            </div>
            
            <div class = "col-lg-3" id="ite">
                <div style="width:100%;text-align:center">
                    <div class="dropdown">
                        <button routerLink="/cursos"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Formación online &nbsp; <i class="fas fa-chevron-down"></i>
                        </button>
                      </div>
                    
                </div>
            </div>
            <div class = "col-lg-2" id="ite">
                <div style="width:100%;text-align:center">
                    <div class="dropdown">
                        <button   id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Otros proyectos &nbsp; <i class="fas fa-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" routerLink = "/programa-red-crea-2">Proyecto RED CREA 2</a>
                          <a class="dropdown-item" routerLink = "/programa-cacomer">Proyecto CACOMER</a>
                          <a class="dropdown-item" style = "color:white" (click) = "goTo('https://iti.castillalamancha.es/')">I.T.I</a>
                        </div>
                      </div>
                    
                </div>
            </div>
            <div routerLink = "/noticias" class = "col-lg-2" id="ite">
                Noticias &nbsp; <i class="fas fa-chevron-down"></i>
            </div>
        </div>
    </div>
</div>
