<table class="table table-sm table-hover" style="background: white;">
            
    <tbody>
        <div class = "col-lg-12">
            
            <span style="font-size: 20px;font-weight: bold;">Lista de Perfil de contratante</span><span style="float: right;font-weight: bold;">Servicios totales: {{totalItems}}</span>
            <div  style="overflow-y: scroll;max-height:200px;width: 100%;">
                <div *ngFor="let servicio of contratadosList">
                    <td style="">
                        <span>Nombre del servicio</span> - {{servicio.nombre}} <br> 
                        <span> Direccion </span> - {{servicio.direccion}}
                    </td>
                    
                    <button (click)="onEdit(servicio)" style="margin-right: 1%;" class = "btn btn-secondary text-white"><i class="far fa-edit"></i></button>
                    <button (click)="onDelete(servicio.$key, servicio.nombre)" class = "btn btn-danger text-white"><i class="far fa-trash-alt"></i></button>
                    
                </div>
            </div>
            <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>


        </div>
        
    </tbody>
</table>
