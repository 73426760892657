<div style="height:40em;width:100%;position: fixed;"></div>
<section id = "seccionCarrousel">
  <div style = "height:100%;">
  
    <div  style = "display:flex;align-items:center;justify-content:center;height:100%;" id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  
  
      <div style = "height:100%;" class="carousel-inner">
        <div style = "width:100%;height:100%;" class="carousel-item active">
          <div id = "img1"></div>
          
        </div>
        <div style = "width:100%;height:100%;" class="carousel-item">
          <div id = "img2"></div>
        </div>
        <div style = "width:100%;height:100%;" class="carousel-item">
          <div id = "img3"></div>
        </div>
        <div style = "width:100%;height:100%;" class="carousel-item">
          <div id = "img4"></div>
        </div>
        <div style = "width:100%;height:100%;" class="carousel-item">
          <div id = "img5"></div>
        </div>
        <div style = "width:100%;height:100%;" class="carousel-item">
          <div id = "img6"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,288L1440,224L1440,320L0,320Z"></path></svg>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>
  </div>
 
</section>
