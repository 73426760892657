import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ProyectoCatorce } from 'src/app/models/proyectocatorce';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-proyecto-catorce',
  templateUrl: './lista-proyecto-catorce.component.html',
  styleUrls: ['./lista-proyecto-catorce.component.css']
})
export class ListaProyectoCatorceComponent implements OnInit {
  totalItems: number;
  proyectocatorceList: ProyectoCatorce[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.proyectocatorceList = [];
    
    this.crudsService.getProyectoCatorceO()
    .snapshotChanges()
    .subscribe(item =>{
      this.proyectocatorceList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.proyectocatorceList.push(x as ProyectoCatorce);
      });
      this.totalItems = this.proyectocatorceList.length;
      
    })

  }

  onEdit(proyecto: ProyectoCatorce){
    this.crudsService.selectProyectoCatorce = Object.assign({},proyecto)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el curso '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteProyectoCatorce($key);
        }
    });
    
  }
}
