import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';
import "firebase/auth";
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  email: string;
  password: string;

  
  constructor(public auth: AngularFireAuth, private router:Router, private http: HttpClient) { }
  logeado: boolean = false
  user = this.auth.authState.pipe(map(authState => {
    if(authState){
      return authState;
    }
  }))
  globalemail:string = ""
  login(){
    let goto = document.getElementById("gastly").textContent;
    this.auth.signInWithEmailAndPassword(this.email, this.password)
    .then(user => {
      this.auth.onAuthStateChanged((usuario) => {
        if(usuario.emailVerified){
          let text = '<br> <i class="fas fa-envelope-square"></i> Debes activar tu cuenta para iniciar sesión! Revisa tu correo electrónico'
          document.getElementById("errors").innerHTML = text + " " + usuario.email
        }else{
          document.getElementById("contenedorLogin").style.display = "none"
          this.email = ''
          this.password = ''
          this.posteffects();
          
        }
      })
      this.router.navigate([goto]);
      this.logeado = true;
    })
    .catch(error => {
      let errors = document.getElementById("errors");
      if(error.message == 'signInWithEmailAndPassword failed: First argument "email" must be a valid string.'){
        errors.innerHTML = '<i class="fas fa-envelope-square"></i> El email no puede estar vacio.';
      }
      else if(error.message == 'signInWithEmailAndPassword failed: Second argument "password" must be a valid string.'){
        errors.innerHTML = '<i class="fas fa-key"></i> La contraseña no puede estar vacia.';
      }
      else if(error.message == 'The password is invalid or the user does not have a password.'){
        errors.innerHTML = '<i class="fas fa-times-circle"></i> Email o contraseña incorrecto.';
      }
      else if(error.message == 'There is no user record corresponding to this identifier. The user may have been deleted.'){
        errors.innerHTML = '<i class="fas fa-user-times"></i> Este usuario no existe.';
      }
    })
  }

  
  public usuario: boolean;
  posteffects(){
    document.getElementById("lgdiv").style.display = "none"
    document.getElementById("postlogin").style.display = "block"
  }

  token: any;
  observer(){

    
    this.auth.onAuthStateChanged((user) =>{
      
      this.token = user.getIdToken();
      if(user.email == 'administrador@adesiman.org'){
        document.getElementById("administracion").style.display = "flex"
      }
      if(user){
        this.globalemail = user.email
        this.posteffects();
        this.usuario = true;
      }
    })
  }






  logout(){
    this.auth.signOut();
  }


}
