<div class = "container-fluid" style = "padding-bottom:7%;background:white;">
    <div class = "row" style = "display: flex;align-items: center;justify-content: center;">
        <div class = "col-md-3" STYLE=""><div style = "text-align: center;font-size: 30px;font-weight: 500;padding-top: 1.5%;padding-bottom: 1.5%;">
            <div style = "margin-left: -10%;" data-aos="fade-in" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <span style="color:#AE8A67">TRABAJAMOS</span> 
                PARA TI</div>
            </div>
        </div>
    </div>
    <div class = "row">
        <div class = "container" >

            
            <div class = "row" style="margin-top:5%;display: flex;align-items: center;justify-content: center;margin-top:8%">
                <div class = "col-md-4" style="height:100%;padding: 0;" >
                    <div style="height:90%;width:100%;display: flex;align-items: center;justify-content: center;" data-aos="fade-up"  data-aos-delay="300">
                        <div style="width:10em;height:10em;background-image:url('../../../../assets/des.jpg');border-radius: 50%;background-size: 100% 100%;"></div>
                    </div>
                    <div style="width:100%;text-align: center;font-size:30px" data-aos="fade-down"  data-aos-delay="600">
                        <br>
                        Eficiencia energética
                    </div>
                </div>
                <div class = "col-md-4" style="height:100%;padding: 0;">
                    <div style="height:90%;width:100%;display: flex;align-items: center;justify-content: center;" data-aos="fade-up"  data-aos-delay="300">
                        <div style="width:11em;height:11em;background-image:url('../../../../assets/pl.jpg');border-radius: 50%;background-size: 100% 100%;"></div>
                    </div>
                    <div style="width:100%;text-align: center;font-size:30px" data-aos="fade-down"  data-aos-delay="600">
                        <br>
                        Consultoria
                    </div>
                </div>
                <div class = "col-md-4" style="height:100%;padding: 0;">
                    <div style="height:90%;width:100%;display: flex;align-items: center;justify-content: center;" data-aos="fade-up"  data-aos-delay="300">
                        <div style="width:12em;height:12em;background-image:url('../../../../assets/mn.jpg');border-radius: 50%;background-size: 100% 100%;"></div>
                    </div>
                    <div style="width:100%;text-align: center;font-size:30px" data-aos="fade-down"  data-aos-delay="600">
                        <br>
                        Digitalización del territorio
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>