import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-territorios',
  templateUrl: './territorios.component.html',
  styleUrls: ['./territorios.component.css']
})
export class TerritoriosComponent implements OnInit {
  semaforo: boolean = false
  public arr: any[];
  constructor() { }

  ngOnInit(): void {
  }
  
firstPart(){
    (async() => {
        let uno = document.getElementById("divpapa");
        uno.className = "container animate__animated animate__fadeOut"
    })().then(() =>{
        setTimeout(function(){ 
            let uno = document.getElementById("divpapa");
            uno.className = "container animate__animated animate__fadeIn animate__slow"
            uno.style.backgroundImage="url(/assets/mapa.jpg)";
            uno.style.maxWidth = "100%"
            document.getElementById("divA").style.display = "none"
            
         }, 800);
    });    
}



}
