import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menutareas',
  templateUrl: './menutareas.component.html',
  styleUrls: ['./menutareas.component.css']
})
export class MenutareasComponent implements OnInit {

  urlactual: string;
  id:string;
  name: any;
  listacursos: Array<any>;
  constructor(public con: CrudsService) { }

  ngOnInit(): void {

    this.con.getCursos().subscribe((res: any) =>{
      this.listacursos = res;
      
    })
  }

  
}
