import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Contratado } from 'src/app/models/contratado';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-perfil',
  templateUrl: './lista-perfil.component.html',
  styleUrls: ['./lista-perfil.component.css']
})
export class ListaPerfilComponent implements OnInit {
  totalItems: number;
  contratadosList: Contratado[];
  lista: string[];

  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.contratadosList = [];
    
    this.crudsService.getContratadosO()
    .snapshotChanges()
    .subscribe(item =>{
      this.contratadosList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.contratadosList.push(x as Contratado);
      });
      this.totalItems = this.contratadosList.length;
    })
  }

  onEdit(servicio: Contratado){
    this.crudsService.selectContratado = Object.assign({},servicio)
  }
  onDelete($key: string, nombre: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el perfil '+ nombre + "?",
        accept: () => {
          this.crudsService.deleteContratado($key);
        }
    });
    
  }

}
