import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Servicio } from 'src/app/models/servicio';
import { CrudsService } from 'src/app/services/cruds.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
  public servicios: any[];
  constructor(public crudsService: CrudsService, private storage: AngularFireStorage, public confirmationService: ConfirmationService, public auth: AuthService) { }
  public completed: boolean
  ngOnInit(): void {


    this.crudsService.getServiciosO()
    .snapshotChanges()
    .subscribe(item =>{
      this.servicios = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.servicios.push(x as Servicio);
      });
    })

  
    
    
  }



  file: File;
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  goTo(data: any){
    window.open(data)
  }

  showFormAdd(){
    document.getElementById("line").style.display = "block"
  }

  closeformadd(){
    document.getElementById("line").style.display = "none"
  }

  onSubmit(serviciosForm: NgForm){

    if(serviciosForm.value.$key == null){
      this.crudsService.insertServicio(serviciosForm.value);
    }
    else
      this.crudsService.updateServicio(serviciosForm.value);
      this.resetForm(serviciosForm);
  }
  resetForm(serviciosForm?: NgForm){
    if(serviciosForm != null){
      serviciosForm.reset();
      this.crudsService.selectServicio = new Servicio();
    }
  }

  uploadPDF() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    console.log(this.file)
    fileRef.put(this.file).then(() => {
      this.completed = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectServicio.direccion = url
      })
    });

  }

  onDelete($key: string, nombre: string){
    this.crudsService.deleteServicio($key)  
    
  }

  


}
