import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JsonserviceService {
  info:any = [];
  cargada = false;
  constructor(private http: HttpClient) { }

  getJson(url: string){
    return this.http.get(url);
  }

}
