<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="600" class = "col-lg-12">
                   
                    <span style="font-size:70px">El territorio</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class = "container" style="padding: 3%;padding-left: 0;">
    <div class = "row">
        <div class = "col-12" style="margin-bottom: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p id ="title">El territorio</p>
            <p>El territorio de intervención de la Mancha Alta y la Sierra Media, se extiende sobre una superficie de 3.549 km2 donde 
                residen 40.049 habitantes, distribuidos en 56 municipios, 2 Entidades de Ámbito Territorial Inferior al Municipio y 68
                localidades o núcleos de población. Es destacable que, de los 40.000 habitantes, un sólo municipio, Tarancón, con alrededor 
                de 13.500 hab., absorbe más de la cuarta parte, lo que hace del territorio una zona eminentemente rural.

                El territorio de intervención se extiende sobre dos comarcas diferenciadas –la Sierra Media y la Mancha Alta-, 
                que, sin embargo, pueden y deben agruparse bajo una única nota de identidad territorial.
                
                Si desde el punto de vista del espacio físico y del paisaje las dos comarcas presentan rasgos 
                diferenciados; desde el punto de vista económico, social, cultural e histórico ambas comarcas presentan elementos comunes. 
                Así, es posible afirmar que pese a su gran extensión y diferenciación paisajística y agraria la zona de actuación mantiene una idiosincrasia 
                que le es característica y definitoria.</p>
        </div>
        <div class = "col-12" style="margin-bottom: 5%;height:40%">
            <p id ="title">Municipios adesiman</p>
            <div class="container" style="height: 35em;display: flex;align-items: center;padding: 0;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                <div class="container" id="divpapa">
                    <div class="container" style="height: 30%;display: flex;align-items: center;justify-content: flex-end;">
                        <div style="width: 55%;height:100%;">
                            <div style="width: 40%;height:100%;display: flex;align-items: center;justify-content: center;">
                                <div (mouseover)="firstPart();" id = "divA"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class = "col-12" style="margin-bottom: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p id ="title">El territorio y el patrimonio</p>
            <p>El territorio de la Sierra Media y Mancha Alta conquense es un espacio rico en recursos desde el punto de vista de la biodiversidad, 
                debido a la complejidad de su orografía y al amplio abanico de ecosistemas que lo componen. En concreto, 29.045 Ha están incluidas en la 
                Red Natura 2000, lo que supone un 8,14% del territorio.

                Desde el punto de vista del paisaje, el territorio se ha visto modificado por las artes agrarias, si bien quedan importantes ecosistemas 
                no antropizados: bosques de encinar, humedales, monumentos geomorfológicos.
                
                El territorio de la Sierra Media y Mancha Alta cuenta con grandes recursos asociado a su diversidad medioambiental; si bien, estos recursos 
                distan aún de poder ser utilizados como una fuente de riqueza y motor de desarrollo. Señalamos a continuación aquellos incluidos en la Red de Espacios Protegidos.
            </p>
            <ul>
                <li>Monasterio de Uclés (1981). Importante complejo monumental con una gran número de visitas anuales (superior a 100.000). Actualmente es el 
                    Seminario Menor de la Diócesis de Cuenca. Es de estilo herreriano y edificado sobre los restos de una antigua fortaleza árabe. Es conocido como 
                    El Escorial de La Mancha.
                </li>
                <li>Yacimiento Arqueológico de Segóbriga (1931) y de Valeria (1977). Importantes vestigios de ciudades romanas. El primero es actualmente Parque Arqueológico.</li>
                <li>   Yacimiento Arqueológico El Cerro de Recuenco en Cervera del Llano. Importante yacimiento de la Edad del Bronce declarado Monumento Histórico-Artístico y Arqueológico.</li>
                <li>Yacimiento Arqueológico de Fuente de la Mota en Barchín del Hoyo (1981). Uno de los yacimientos prehistóricos más significativos de la meseta, donde se ha localizado un poblado prerromano.</li>
                <li>Complejo minero de “Lapis specularis”. Red de minas que se extiende por los territorios de la Alcarria Conquense y la Mancha Alta y que fueron determinantes para la romanización (asentamientos) del territorio.</li>

            </ul>
           <p style="font-weight:600">Edificaciones Religiosas declaradas Bien de Interés Cultural:</p>
           <ul>
               <li>Iglesia de la Natividad de Arcas del Villar (1982). Uno de los ejemplos más originales del románico conquense, del siglo XIII.</li>
               <li>Iglesia de la Asunción de Barchín del Hoyo del siglo XVIII (1992).</li>
               <li>Iglesia de San Miguel Arcángel de Belinchón (1972).</li>
               <li>Iglesia de la Natividad de Carrascosa del Campo (1972). Una de las mejores iglesias de la Diócesis de Cuenca y con gran interés y valor artístico.</li>
               <li>Iglesia Nuestra Señora de la Asunción de Montalbanejo (1983).</li>
               <li>Iglesia Parroquial de Mota de Altarejos (proceso iniciado en 2001).</li>
               <li>Iglesia de la Asunción de Tarancón (proceso iniciado en 2002).</li>
               <li>Iglesia Nuestra Señora de la Sey (1982). Mayor iglesia románica conservada en la provincia cuya construcción se llevó a cabo con parte del material de la antigua ciudad romana de Valeria.</li>
           </ul>
        </div>
    </div>
    <div class = "col-12" style="margin-bottom: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <p id ="title">Patrimonio etnográfico</p>
        <p>En relación con las construcciones etnográficas el territorio cuenta con importantes ejemplos de molinos de agua y de viento, los lavaderos, 
            las antiguas fuentes y los chozos de pastores. No obstante, más importante que las construcciones son los eventos culturales que configuran 
            las señas de identidad de la comarca y que cuentan con amplio reconocimiento regional e incluso nacional. Es el caso de las fiestas tradicionales,
             tan arraigadas en esta comarca. Estas fiestas son el ejemplo del mejor Folklore popular y han supuesto la recuperación de cantares, bailes, vestidos 
             y gastronomía. Mención especial merecen
        </p>
        <ul>
            <li>La Endiablada de Almonacid del Marquesado. Fiesta declarada de Interés Turístico Nacional. Se celebra a principios del mes de Febrero en honor a San Blas y La Virgen de la Candelaria.</li>
            <li>Fiestas de Moros y Cristianos de Valverde del Júcar. Fiesta declarada de Interés Turístico Regional. Se celebra a principios del mes de Enero, en honor al Santo Niño.</li>
            <li>El Vítor de Horcajo de Santiago. Fiesta de Interés Turístico Regional. Se celebra en honor a la Inmaculada Concepción en el mes de Diciembre.</li>
            <li> Pasión Viviente y Semana Santa de Tarancón. Declarada de Interés Turístico Regional. Es un acontecimiento que dramatiza la pasión, muerte y resurrección de Jesucristo en escenarios naturales todos los Sábados de Gloria.</li>
        </ul>
    </div>
</div>
<app-footer></app-footer>
