<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Proyecto 2014 - 2021</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class="container" style="padding-bottom: 3%;">
    <div class="row" style="padding-top: 3%;">
        <div class="col-lg-12"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p style="font-size: 20px;">LEADER 2014 - 2020 Convocatoria en vigor.</p>
            <span style="display: block;">Documentación de interés:</span>
            <br>
            <div *ngFor = "let servicio of servicios">
                <p><i class="far fa-file"></i> &nbsp; <a style="color:#5256B3" class="linkClass" (click)="goTo(servicio.direccion)">{{servicio.nombre}}.</a></p>
            </div>
            <span>Proyectos seleccionados, inversiones aceptadas y subvenciones concedidas y pagadas - Ejercicios 2017,2018 y 2019</span>
            
        </div>
    </div>
</div>
<app-footer></app-footer>