import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Servicio } from 'src/app/models/servicio';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-servicios',
  templateUrl: './lista-servicios.component.html',
  styleUrls: ['./lista-servicios.component.css']
})
export class ListaServiciosComponent implements OnInit {
  totalItems: number;
  serviciosList: Servicio[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.serviciosList = [];
    
    this.crudsService.getServiciosO()
    .snapshotChanges()
    .subscribe(item =>{
      this.serviciosList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.serviciosList.push(x as Servicio);
      });
      this.totalItems = this.serviciosList.length;
    })

  }

  onEdit(servicio: Servicio){
    this.crudsService.selectServicio = Object.assign({},servicio)
  }
  onDelete($key: string, nombre: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el servicio '+ nombre + "?",
        accept: () => {
          this.crudsService.deleteServicio($key);
        }
    });
    
  }

}
