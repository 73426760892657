<div class ="container-fluid" style="background: #1c1c1c;padding: 0.5%;">
    <div class="container">
        <div class="row">
            <div routerLink = "/menu-pueblos" class="col item col-lg-2" style="color:white"><i class="fas fa-database"></i>&nbsp;PUEBLOS</div>
            <div routerLink = "/pruebas" class="col item col-lg-2" style="color:white"><i class="far fa-newspaper"></i>&nbsp;NOTICIAS</div>         
            <div routerLink = "/menu-programas" class="col item col-lg-2" style="color:white"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;PROGRAMAS</div>
            <div routerLink = "/menu-junta" class="col item col-lg-2" style="color:white"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;JUNTA</div>
            <div routerLink = "/menu-cursos" class="col item col-lg-2" style="color:white"><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;CURSOS</div>
            <div routerLink = "/menu-servicios-perfil" class="col item col-lg-2" style="color:white"><i class="fa fa-server" aria-hidden="true"></i>&nbsp;SERVICIOS Y PERFIL</div>
        </div>
    </div>
</div>
<div class ="container-fluid" style="background: #1c1c1c;padding: 0.5%;">
    <div class="container">
        <div class="row">
            <div routerLink = "/menu-ofertas" class="col item col-lg-2" style="color:white"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;OFERTAS</div>
            <div routerLink = "/menu-usuarios" class="col item col-lg-2" style="color:white"><i class="fa fa-server" aria-hidden="true"></i>&nbsp;USUARIOS</div>
        </div>
    </div>
</div>

