import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { Oferta } from 'src/app/models/oferta';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-ofertas',
  templateUrl: './menu-ofertas.component.html',
  styleUrls: ['./menu-ofertas.component.css']
})
export class MenuOfertasComponent implements OnInit {

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
  }

  file: File;
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

  public completed: boolean;
  uploadPDF() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectOferta.direccion = url
      })
    });
  }


  onSubmit(ofertasForm: NgForm){
    if(ofertasForm.value.$key == null)
      this.crudsService.insertOferta(ofertasForm.value);
    else
      this.crudsService.updateOferta(ofertasForm.value);
      this.resetForm(ofertasForm);
  }
  resetForm(ofertasForm?: NgForm){
    if(ofertasForm != null){
      ofertasForm.reset();
      this.crudsService.selectOferta = new Oferta();
    }
  }

}
