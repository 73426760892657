import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Resultado } from 'src/app/models/resultado';
import { Tiempo } from 'src/app/models/tiempo';
import { AuthService } from 'src/app/services/auth.service';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-contenidocursos',
  templateUrl: './contenidocursos.component.html',
  styleUrls: ['./contenidocursos.component.css']
})
export class ContenidocursosComponent implements OnInit, OnDestroy {
  verdad: boolean = false;
  listares: Array<any> = [];
  urlactual: string;
  id: string;
  agenda: Array<any>;
  lecciones: Array<any> = [];
  tareas: Array<any> = [];
  examenes: Array<any> = [];
  resultadosList: Resultado[] = new Array<Resultado>();
  tiemposList: Tiempo[] = new Array<Tiempo>();
  tiempo: Tiempo = new Tiempo();
  tieneTiempos: boolean;
  tiempoTranscurrido: number = 0;
  
  constructor(public crud: CrudsService, public auth: AuthService, private router: Router, private db: AngularFireDatabase) { }

  ngOnInit(): void {
    this.urlactual = window.location.toString();
    this.id = this.getId(this.urlactual);

    console.log(this.id)

    this.crud.getCursos().subscribe((res: any) => {
      this.findById(res, this.id)
    })

    setInterval(() => {
      this.tiempoTranscurrido++;
    }, 1000);
    
    this.crud.getResultadosO()
    .snapshotChanges()
    .subscribe(item =>{
      this.resultadosList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.resultadosList.push(x as Resultado);
        
      });
      
    })


    this.crud.getTiemposO()
    .snapshotChanges()
    .subscribe(item =>{
      this.tiemposList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        if(x["alumno"] == this.auth.globalemail){
          this.tiemposList.push(x as Tiempo);
        }
        
      });
      
    })
  }

  

  existeTiempo: boolean = false;
  existeTiempo2: boolean = false;
  ngOnDestroy() {

    var tiempo = 0
    var key = ""
    if(this.tiemposList.length > 0){ 

  
      this.tiemposList.forEach(element => {
        if(element.alumno == this.auth.globalemail && element.curso == this.id){  
          
          tiempo = element.tiempo
          key = element.$key
          this.existeTiempo = true
        }else{
          this.existeTiempo2 = true
        }
      });
    }else{
      this.tiempo.alumno = this.auth.globalemail
      this.tiempo.curso = this.id
      this.tiempo.tiempo = this.tiempoTranscurrido
      this.crud.insertTiempo(this.tiempo)
    }

    if(this.existeTiempo){
      this.tiempo.alumno = this.auth.globalemail
      this.tiempo.curso = this.id
      this.tiempo.tiempo = tiempo + this.tiempoTranscurrido
      this.crud.insertTiempo(this.tiempo)
      this.crud.deleteTiempo(key)
    }
    if(this.existeTiempo2 && !this.existeTiempo){
        this.tiempo.alumno = this.auth.globalemail
        this.tiempo.curso = this.id
        this.tiempo.tiempo = this.tiempoTranscurrido
        this.crud.insertTiempo(this.tiempo)  
    }
    
  }
  
  

  startTime: number;
  timeElapsed: number = 0;

  

  findById(res: any, id: string){
    for(var i = 0; i < res.length; i++){
      if(res[i].id == id){
        this.agenda = res[i].agenda
        this.lecciones = (res[i].lecciones)
        this.tareas = res[i].tareas
      }
    }
  }
  findExamsbyId(res: any, id: string): any[]{
    this.examenes = [];
    for(var i = 0; i < res.length; i++){
      if(res[i].id == id){
        this.examenes.push(res[i])
      }
    }
    return this.examenes;
    
  }
  restoreList(list: Array<any>){
    var xcosa = list[0]
    while(xcosa != ','){}
  }
  getId(url:string){
    var contador = 0;
    var id = "";
    for(var i = 0; i < url.length; i++){
      if(url[i] == '/'){
        contador++;
      }
      if(contador == 4){
        id = id + url[i];
      }
    }
    id = id.replace('/', '')
    return id;
  }

  openEmail(){
    alert("Asegurate de poner tu nombre y usuario de adesiman en el correo electronico  ")
    window.open('mailto:adesimanwebweb@gmail.com', '_blank');
  }
 
  public agendab: boolean;
  listagenda: Array<any> = [];
  agendacontent(name: string){
    this.examenesb = false;
    this.agendab = true;
    this.leccionesb = false;
    this.resultadosb = false;
    this.tareasb = false;
    this.listares = [];
    this.listagenda = [];
    this.listares.push(this.agenda, name)
    
    this.agenda.forEach(element => {
      this.listagenda.push(element)
    });
  }
  public tareasb: boolean;
  listareas: Array<any> = [];
  tareascontent(name: string){
    this.examenesb = false;
    this.agendab = false;
    this.resultadosb = false;
    this.leccionesb = false;
    this.tareasb = true;
    this.listares = [];
    this.listareas = [];
    this.listares.push(this.tareas, name)
    this.tareas.forEach(element => {   
      this.listareas.push(element)
    })
  }
  public leccionesb: boolean;
  listalecciones: Array<any> = [];
  leccionescontent(name: string){
    this.examenesb = false;
    this.agendab = false;
    this.resultadosb = false;
    this.leccionesb = true;
    this.tareasb = false;
    this.listares = [];
    this.listalecciones = []
    this.listares.push(this.lecciones, name)
  
    this.lecciones.forEach(element => {
      this.listalecciones.push(element)
    })
  }
  public examenesb: boolean;
  listaexamenes: Array<any> = [];
  examenescontent(name: string){
    this.examenesb = true
    this.resultadosb = false
    this.agendab = false;
    this.leccionesb = false;
    this.tareasb = false;
    this.listares = [];
    this.listaexamenes = [];
    this.listares.push(this.examenes, name)  
    this.crud.getExamenes().subscribe((res: any) => {
      this.urlactual = window.location.toString();
      this.id = this.getId(this.urlactual);
      this.listaexamenes = this.findExamsbyId(res, this.id)
    })
  }
  public resultadosb: boolean;
  misResultados: Resultado[] = new Array<Resultado>();
  resultadoscontent(name: string){
    
    this.misResultados = []
    this.resultadosList.forEach(element => {
      if(element.alumno == this.auth.globalemail && element.curso == this.id){
        this.misResultados.push(element)
      }
    });

    this.resultadosb = true
    this.examenesb = false;
    this.agendab = false;
    this.leccionesb = false;
    this.tareasb = false;
    this.listares = [];
    this.listares.push({}, name)
  }
  public atalumno: boolean;
  atalumnocontent(name: string){
    this.examenesb = false;
    this.agendab = false;
    this.leccionesb = false;
    this.resultadosb = false;
    this.tareasb = false;
    this.listares = [];
    this.listares.push({}, name)
  }

  goTo(data: any){
    window.open(data)
  }


  


}
