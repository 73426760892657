import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proder',
  templateUrl: './proder.component.html',
  styleUrls: ['./proder.component.css']
})
export class ProderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goTo(data: any){
    window.open(data)
  }

}
