export class Pueblo {

    $key: string;
    PUEBLO: string;
    'ASOCIACIONES Y OTROS COLECTIVOS': string;
    'AUTOBÚS O TREN': string;
    ALOJAMIENTO: string;
    AYUNTAMIENTO: string;
    'BANCOS Y CAJAS': string;
    'BARES Y RESTAURANTES': string;
    'CENTRO DE SALUD': string;
    'CENTROS EDUCATIVOS': string;
    COOPERATIVAS: string;
    FARMACIAS: string;
    FIESTAS: string;
    FOTO: string;
    GASOLINERAS: string;
    GUARDERIAS: string;
    'GUARDIA CIVIL': string;
    'LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL': string;
    MUSEOS: string;
    NEGOCIOS: string;
    OTROS: string;
    RESIDENCIAS: string;
    VETERINARIOS: string;
    'ZONAS DE OCIO Y DESCANSO': string;
    'ZONAS DEPORTIVAS': string;
     examenes: Array<number>;   
}
