<div class="container-fluid" style="background:#E1F1F5;height: 100%;padding:0;">
       
    <app-header-admin></app-header-admin>
    <div style="padding-left: 5%;padding-right: 5%;padding-top: 5%;">  
    <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
    <p style="font-size: 20px;">Menu para añadir o editar integrantes de la junta</p>
    <div class="row">
        <div class="col-4" style="padding-top: 1%;">
            <form #juntaForm="ngForm" (ngSubmit)="onSubmit(juntaForm)">
                <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectJunta.$key">
                <!-- AQUI SE ESCRIBE EL TITULO -->
                <input name="asociacion" 
                type="text" 
                class="inputclass" 
                #name="ngModel" 
                [(ngModel)]="crudsService.selectJunta.asociacion"
                placeholder="Asociacion"
                >

                <input name="cargo" 
                type="text" 
                class="inputclass" 
                #name="ngModel" 
                [(ngModel)]="crudsService.selectJunta.cargo"
                placeholder="Cargo"
                >

                <input name="representacion" 
                type="text" 
                class="inputclass" 
                #name="ngModel" 
                [(ngModel)]="crudsService.selectJunta.representacion"
                placeholder="Representacion"
                >
                
                <input name="titular" 
                type="text" 
                class="inputclass" 
                #name="ngModel" 
                [(ngModel)]="crudsService.selectJunta.titular"
                placeholder="Titular"
                >
                <!-- AQUI VA LA DIRECCION DE LA IMAGEN, tipo: '/assets/nombredeimagen.jpg' -->
                
                <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                  <div class="label" fxFlex="30">IMAGEN</div>
                  <div fxFlex="30" >
                    <input type="file" name="file" (change)="onFileSelect($event)"/>
                    <div id = "botonCargar">Cargar</div>
                  </div>
                  <div fxFlex="30">
                    
                    <div class="label" fxFlex="30" *ngIf="completed">Archivo cargado</div>
                  </div>
                </section>

                
                <input name="foto" 
                id = "imagen"
                type="text" 
                class="inputclass" 
                #name="ngModel" 
                [(ngModel)]="crudsService.selectJunta.foto"
                placeholder="Imagen del integrante"
                >

                <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                    Agregar
                </button>
                <button class="btn btn-secondary" type="reset"(click)="resetForm(juntaForm)">
                    Limpiar
                </button>
            </form>
        </div>
        <div class="col-6" style="background: white;">
            
           <app-lista-junta></app-lista-junta>
        </div>

    </div>
</div>

</div>