

<!-- ESTE ES EL MENU DE NOTICIAS -->
<div>
    
    <div class="container-fluid" style="background:#E1F1F5;height: 100%;padding:0;">

            <app-header-admin></app-header-admin>
            <div style="padding-left: 5%;padding-right: 5%;padding-top: 2%;">  
            <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
            <p style="font-size: 20px;">Menu para añadir o editar noticias</p>
            <div class="row">
                <div class="col-12" style="background: white;">
                    
                    <app-listapueblos></app-listapueblos>
                </div>

                <div _ngcontent-bri-c53="" class="col-12" style="padding-top: 1%; background: whitesmoke; margin-top: 3em; margin-bottom: 3em;">
                    <form #pueblosForm="ngForm" (ngSubmit)="onSubmit(pueblosForm)">
                      
                        <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectPueblo.$key">
                        <div _ngcontent-bri-c53="" class="row">
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectPueblo.$key">
                                <!-- AQUI SE ESCRIBE EL TITULO -->
                                <input name="PUEBLO" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.PUEBLO"
                                placeholder="Nombre del pueblo"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="ALOJAMIENTO" 
                                id = "imagen"
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.ALOJAMIENTO"
                                placeholder="Alojamientos del pueblo"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="AYUNTAMIENTO" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.AYUNTAMIENTO"
                                placeholder="Ayuntamientos del pueblo"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="ASOCIACIONES Y OTROS COLECTIVOS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['ASOCIACIONES Y OTROS COLECTIVOS']"
                                placeholder="ASOCIACIONES Y OTROS COLECTIVOS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="AUTOBÚS O TREN" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['AUTOBÚS O TREN']"
                                placeholder="AUTOBÚS O TREN"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="NEGOCIOS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.NEGOCIOS"
                                placeholder="NEGOCIOS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="BANCOS Y CAJAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['BANCOS Y CAJAS']"
                                placeholder="BANCOS Y CAJAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="BARES Y RESTAURANTES" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['BARES Y RESTAURANTES']"
                                placeholder="BARES Y RESTAURANTES"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="CENTRO DE SALUD" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['CENTRO DE SALUD']"
                                placeholder="CENTRO DE SALUD"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="CENTROS EDUCATIVOS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['CENTROS EDUCATIVOS']"
                                placeholder="CENTROS EDUCATIVOS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="COOPERATIVAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.COOPERATIVAS"
                                placeholder="COOPERATIVAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="FARMACIAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.FARMACIAS"
                                placeholder="FARMACIAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="FIESTAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.FIESTAS"
                                placeholder="FIESTAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="FOTO" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.FOTO"
                                placeholder="FOTO"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="GASOLINERAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.GASOLINERAS"
                                placeholder="GASOLIENRAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="GUARDERIAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.GUARDERIAS"
                                placeholder="GUARDERIAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL']"
                                placeholder="LUGARES DE INTERÉS TURÍSTICOS y PATRIMONIAL"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="MUSEOS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.MUSEOS"
                                placeholder="MUSEOS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="OTROS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.OTROS"
                                placeholder="OTROS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="RESIDENCIAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.RESIDENCIAS"
                                placeholder="RESIDENCIAS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="VETERINARIOS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo.VETERINARIOS"
                                placeholder="VETERINARIOS"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="ZONAS DE OCIO Y DESCANSO" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['ZONAS DE OCIO Y DESCANSO']"
                                placeholder="ZONAS DE OCIO Y DESCANSO"
                                >
                            </div>
                            <div _ngcontent-bri-c53="" class="col-md-4">
                                <input name="ZONAS DEPORTIVAS" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectPueblo['ZONAS DEPORTIVAS']"
                                placeholder="ZONAS DEPORTIVAS"
                                >
                            </div>
                        </div>
                        
                    
                    <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                        Agregar
                    </button>
                    <button class="btn btn-secondary" type="reset"(click)="resetForm(pueblosForm)">
                        Limpiar
                    </button>

                    </form>
                </div>
                
            </div>
        </div>
        
    </div>
</div>
