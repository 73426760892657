import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';
import { JsonserviceService } from 'src/app/services/jsonservice.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  public noticiaslist: any[];
  public imagenes: any[];
  
  constructor(private crudsService: CrudsService) { }

  ngOnInit(): void {
   this.crudsService.getNoticiasO().subscribe((res: any) => {
    this.noticiaslist = res
     this.imagenes = this.ordenarLista(this.noticiaslist);
   })
  }

  goTo(data: any){
    window.open(data)
  }

  ordenarLista(data: any[]): any[]{
    let nums = [];
    for(var i = data.length - 1; i >=0; i--){
        nums.push(data[i])
    }
    return nums;
  }

 

}
