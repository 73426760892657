import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { CarruselComponent } from './components/home/carrusel/carrusel.component';
import { DestacadosComponent } from './components/home/destacados/destacados.component';
import { InformacionComponent } from './components/home/informacion/informacion.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { FormacionComponent } from './components/formacion/formacion.component';
import { SubvencionesComponent } from './components/subvenciones/subvenciones.component';
import { NewsComponent } from './components/news/news.component';
import { HttpClientModule} from '@angular/common/http';
import { TerritoriosComponent } from './components/territorios/territorios.component';
import { PueblosComponent } from './components/pueblos/pueblos.component';
import { SobrenosotrosComponent } from './components/home/sobrenosotros/sobrenosotros.component';
import { ProderComponent } from './components/proder/proder.component';
import { RedcreaComponent } from './components/redcrea/redcrea.component';
import { CacomerComponent } from './components/cacomer/cacomer.component';
import { FormsModule } from '@angular/forms';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { Proyecto14Component } from './components/proyecto14/proyecto14.component';
import { Proyecto7Component } from './components/proyecto7/proyecto7.component';
import { BuscadorPueblosComponent } from './components/buscador-pueblos/buscador-pueblos.component';
import { NuestrasNoticiasComponent } from './components/nuestras-noticias/nuestras-noticias.component';
import { FilterPipe } from './pipes/filter.pipe';
import {AngularFireModule} from '@angular/fire'
import {AngularFireDatabaseModule} from '@angular/fire/database'
import {CrudsService} from '../app/services/cruds.service';
import { PruebasComponent } from './components/pruebas/pruebas.component';
import { ListanoticiasComponent } from './components/pruebas/listanoticias/listanoticias.component'
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogosComponent } from './components/home/logos/logos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BasededatosComponent } from './components/pruebas/basededatos/basededatos.component';
import { ListapueblosComponent } from './components/pruebas/basededatos/listapueblos/listapueblos.component';
import { MenutareasComponent } from './components/menutareas/menutareas.component';
import { ContenidocursosComponent } from './components/contenidocursos/contenidocursos.component';
import { ExamenComponent } from './components/examen/examen.component';
import { SobreAdesimanComponent } from './components/sobre-adesiman/sobre-adesiman.component';
import { PerfilDelContratanteComponent } from './components/perfil-del-contratante/perfil-del-contratante.component';
import { HeaderAdminComponent } from './components/pruebas/header-admin/header-admin.component';
import { MenuJuntaComponent } from './components/pruebas/menu-junta/menu-junta.component';
import { ListaJuntaComponent } from './components/pruebas/menu-junta/lista-junta/lista-junta.component';
import { MenuServiciosPerfilComponent } from './components/pruebas/menu-servicios-perfil/menu-servicios-perfil.component';
import { ListaServiciosPerfilComponent } from './components/pruebas/menu-servicios-perfil/lista-servicios-perfil/lista-servicios-perfil.component';
import { ListaServiciosComponent } from './components/pruebas/menu-servicios-perfil/lista-servicios/lista-servicios.component';
import { ListaPerfilComponent } from './components/pruebas/menu-servicios-perfil/lista-perfil/lista-perfil.component';
import { MenuCursosComponent } from './components/pruebas/menu-cursos/menu-cursos.component';
import { ListaCursosComponent } from './components/pruebas/menu-cursos/lista-cursos/lista-cursos.component';
import { MenuProgramasComponent } from './components/pruebas/menu-programas/menu-programas.component';
import { ListaProyectoCatorceComponent } from './components/pruebas/menu-programas/lista-proyecto-catorce/lista-proyecto-catorce.component';
import { ListaProyectoSieteComponent } from './components/pruebas/menu-programas/lista-proyecto-siete/lista-proyecto-siete.component';
import { ListaProyectoProderComponent } from './components/pruebas/menu-programas/lista-proyecto-proder/lista-proyecto-proder.component';
import { OfertasDeTrabajoComponent } from './ofertas-de-trabajo/ofertas-de-trabajo.component';
import { OfertasTrabajoComponent } from './components/ofertas-trabajo/ofertas-trabajo.component';
import { MenuOfertasComponent } from './components/pruebas/menu-ofertas/menu-ofertas.component';
import { ListaOfertasComponent } from './components/pruebas/lista-ofertas/lista-ofertas.component';
import { OfertasListaComponent } from './components/pruebas/menu-ofertas/ofertas-lista/ofertas-lista.component';
import { MenuUsuariosComponent } from './components/pruebas/menu-usuarios/menu-usuarios.component';
import { ListaUsuariosComponent } from './components/pruebas/menu-usuarios/lista-usuarios/lista-usuarios.component';
import { Proyecto23Component } from './components/proyecto23/proyecto23.component';
import { ListaProyectoVtresComponent } from './components/pruebas/menu-programas/lista-proyecto-vtres/lista-proyecto-vtres.component'
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CarruselComponent,
    DestacadosComponent,
    InformacionComponent,
    ServiciosComponent,
    FormacionComponent,
    SubvencionesComponent,
    NewsComponent,
    TerritoriosComponent,
    PueblosComponent,
    SobrenosotrosComponent,
    MenutareasComponent,
    ProderComponent,
    RedcreaComponent,
    CacomerComponent,
    Proyecto14Component,
    Proyecto7Component,
    BuscadorPueblosComponent,
    NuestrasNoticiasComponent,
    FilterPipe,
    PruebasComponent,
    ListanoticiasComponent,
    LogosComponent,
    BasededatosComponent,
    ListapueblosComponent,
    ContenidocursosComponent,
    ExamenComponent,
    SobreAdesimanComponent,
    PerfilDelContratanteComponent,
    HeaderAdminComponent,
    MenuJuntaComponent,
    ListaJuntaComponent,
    MenuServiciosPerfilComponent,
    ListaServiciosPerfilComponent,
    ListaServiciosComponent,
    ListaPerfilComponent,
    MenuCursosComponent,
    ListaCursosComponent,
    MenuProgramasComponent,
    ListaProyectoCatorceComponent,
    ListaProyectoSieteComponent,
    ListaProyectoProderComponent,
    OfertasDeTrabajoComponent,
    OfertasTrabajoComponent,
    MenuOfertasComponent,
    ListaOfertasComponent,
    OfertasListaComponent,
    MenuUsuariosComponent,
    ListaUsuariosComponent,
    Proyecto23Component,
    ListaProyectoVtresComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ConfirmDialogModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    ReactiveFormsModule
  ],
  providers: [CrudsService,ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
