import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-perfil-del-contratante',
  templateUrl: './perfil-del-contratante.component.html',
  styleUrls: ['./perfil-del-contratante.component.css']
})
export class PerfilDelContratanteComponent implements OnInit {

  constructor(private crudsService: CrudsService) { }
  public contratados: any[];
  ngOnInit(): void {

    this.crudsService.getContratados().subscribe((res: any) => {
      this.contratados = res
    })
  }

  goTo(data: any){
    window.open(data)
  }


}
