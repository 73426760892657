import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private crudsService: CrudsService) { } 
  public noticiaslist: any[];
  public imagenes: any[];
  public noticiasFoot: any[];

  ngOnInit(): void {
    this.crudsService.getNoticiasO().subscribe((res: any) => {
      this.noticiaslist = res
       this.imagenes = this.ordenarLista(this.noticiaslist);
     })
  }


 
 
   ordenarLista(data: any[]): any[]{
     let nums = [];
     for(var i = data.length - 1; i >=0; i--){
        if(nums.length < 3){
          nums.push(data[i])
        }
     }
     return nums;
   }

  goTwitter(){
    location.href ='https://twitter.com/ADESIMAN_';
  }

  goTo(data: any){
    window.open(data)
  }

}
