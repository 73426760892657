<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12">
                   
                    <span style="font-size:70px">Programa PRODER</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class="container" style="padding-bottom: 5%;">
    <div class="row" style="padding-top: 5%;"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <p style="font-size: 20px;">Programa PRODER</p>
    </div>
    <div class="row"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <div class="col-lg-3" style="height: 20em;background-image: url('/assets/proder1.jpg');background-size: 100% 100%;background-repeat: no-repeat;"></div>
        <div class="col-lg-3" style="height: 20em;display: flex;align-items: center;padding:0;justify-content: center;">
            <div style="width:90%;height:100%;background:white">
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid white;">
                    1997 - 2000
                </div>
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid white;">
                    <a style="color:white" class="linkClass" (click)="goTo('https://adesiman.org/programa_de_desarrollo/programa_2007-2013/proder1.pdf')">PRODER 1</a>
                </div>
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;">
                    RESULTADOS
                </div>
            </div>
        </div>
        <div class="col-lg-3" style="height: 20em;background-image: url('/assets/proder2.jpg');background-size: 100% 100%;background-repeat: no-repeat;"></div>
        <div class="col-lg-3" style="height: 20em;display: flex;align-items: center;padding:0;justify-content: center;">
            <div style="width:90%;height:100%;background:white">
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid white;">
                    2002 - 2008
                </div>
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid white;">
                    <a style="color:white" class="linkClass" (click)="goTo('https://adesiman.org/programa_de_desarrollo/programa_2007-2013/proder2.pdf')">PRODER 2</a>
                </div>
                <div style="width:100%;height:33.3%;background:#394f69;color:white;display: flex;align-items: center;justify-content: center;">
                    RESULTADOS
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
