import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Noticia } from 'src/app/models/noticia';
import { CrudsService } from 'src/app/services/cruds.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Pueblo } from 'src/app/models/pueblo';

@Component({
  selector: 'app-basededatos',
  templateUrl: './basededatos.component.html',
  styleUrls: ['../pruebas.component.css']
})
export class BasededatosComponent implements OnInit {
  file: File;
  onFileSelect(event) {
   if (event.target.files.length > 0) {
     this.file = event.target.files[0];
   }
 }
 
   constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }
   completed;
   ngOnInit(): void {
    this.crudsService.selectPueblo = new Pueblo();
   }
 
  
   onSubmit(pueblosForm: NgForm){
     if(pueblosForm.value.$key == null){
      this.crudsService.insertPueblo(pueblosForm.value);
      this.resetForm(pueblosForm);
     }
     this.crudsService.updatePueblo(pueblosForm.value);
     this.resetForm(pueblosForm);
   }
   resetForm(pueblosForm?: NgForm){
     if(pueblosForm != null){
       pueblosForm.reset();
       this.crudsService.selectPueblo = new Pueblo();
     }
   }

}
