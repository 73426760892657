import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if( arg === '' || arg.length < 3) return value;
    const resultDatos = [];
    for(const data of value){
      if(data.PUEBLO.toLowerCase().indexOf(arg.toLowerCase()) > -1){
        console.log("yea")
        resultDatos.push(data)
      } 
    }
    return resultDatos
  }

}
