<app-header></app-header>
<div id = "goku" class="container-fluid" style="background:whitesmoke;padding-top: 1.5%;padding-bottom:2%;">
    <div class="container" style="margin-bottom: 2em;">
        <h4>Busca en nuestro territorio</h4>
    </div>
    <div class="container">
       <div class="row" style="padding-bottom:1.5%;padding-left:1.5%;">
           <div class = "col-lg-3" style="height:4em;padding:0">
                <span style="display: block;margin-bottom: 0.5em;">Busca por pueblo</span>
                <input (click)="come()" [disabled]="isEnabledp" [(ngModel)]="filterDatos" name = "filterDatos" style="width: 90%;" type="text" class="form-control" id="exampleFormControlInput1" placeholder="Nombre del pueblo">
            </div>
            <div class = "col-lg-3" style="height:4em;padding:0">
                <span style="display: block;margin-bottom: 0.5em;">Busca por selección</span>
                <select [(ngModel)]="opcionSeleccionado" (change)="capturar()" style="width: 90%;padding: 2.2%;border:1px solid #ccc;border-radius: 3px;">
                    <option value="0">Selecciona una opción</option>
                    <option >Gasolineras</option>
                    <option >Fiestas</option>
                    <option >Ayuntamiento</option>
                    <option >Farmacias</option>
                    <option >Lugares</option>
                    <option >Guardia civil</option>
                    <option >Asociaciones</option>
                    <option >Bares</option>
                    <option >Cooperativas</option>
                    <option >Zonas deportivas</option>
                    <option >Bancos y cajas</option>
                    <option >Centro de salud</option>
                </select>
                    
            </div>
            <div class = "col-lg-3" style="height:4em;padding:0">
                <span style="display: block;margin-bottom: 0.5em;visibility: hidden;">Busca por pueblo</span>
                <button style="width: 90%;" type="button" class="btn btn-primary">Buscar &nbsp;<i class="fas fa-search"></i></button>
            </div>
       </div>
    </div>
</div>
<br>
<div class="container">
    <div class="row">
        <div class="col">
            <table id = "tabla1" class="table table-striped">
                <thead>
                    <tr>
                        <th>Pueblo</th>
                        <th>Ayuntamiento</th>
                        <th>Centro de salud</th>
                        <th>Farmacias</th>
                        <th>Fiestas</th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr *ngFor="let items of datospueblos | filter:filterDatos">
                        <td style="max-width: 500px;">{{items.PUEBLO}}</td>
                        <td style="max-width: 500px;">{{items.AYUNTAMIENTO}}</td>
                        <td style="max-width: 300px;">{{items.CENTRODESALUD}}</td>
                        <td style="max-width: 300px;">{{items.FARMACIAS}}</td>
                        <td style="max-width: 300px;">{{items.FIESTAS}}</td>
                    </tr>
                </tbody>
            </table>
            <table id = "tablaayuntamiento" class="table table-striped">
                <thead>
                    <tr>
                        <th>Pueblo</th>
                        <th id = "data"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let items of dict">
                        <td>{{items.key}}</td>
                        <td>{{items.value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<br><br>
<app-footer></app-footer>