<app-logos></app-logos>
<div class = "container-fluid" style = "background:#141D28">
    <div class = "container" style ="height:100%;">
        <div class = "row" style = "height:100%;padding:5%">
            <div class = "col-md-4" style = "height:100%;">
                <div style="width: 100%;height: 100%;">
                    <p style="letter-spacing: 1pt;color:#0AC775">SIGUENOS EN TWITTER</p>
                     <div style="font-size: 18px;color:white">
                        <i (click)="goTwitter()" class="fab fa-twitter-square" style = "margin-right: 6%;cursor:pointer;font-size:38px"></i>
                     </div>
                </div>
            </div>
            <div class = "col-md-4" style = "height:100%;">
                <div style="width: 100%;height: 100%;">
                    <p style="letter-spacing: 1pt;color:#0AC775">NOTICIAS RECIENTES</p>
                    <p (click)="goTo(noticia.url)" *ngFor = "let noticia of this.imagenes" style="cursor:pointer;color:white;border-bottom: 1px solid whitesmoke;padding-bottom: 5%;"><i style="color:#0AC775" class="fas fa-caret-right"></i>{{noticia.titulo}}</p>
                </div>
            </div>
            <div class = "col-md-4" style = "height:100%;">
                <div style="width: 100%;height: 100%;">
                    <p style="letter-spacing: 1pt;color:#0AC775">CONTACTA CON NOSOTROS</p>
                    <p style="color:white;margin-top: 18%;padding-bottom: 5%;"><i style="color:#0AC775;margin-right: 3%;" class="fas fa-map-marker-alt"></i>JUAN GAVALA S/ N CP 16555 CARRASCOSA DEL CAMPO</p>
                    <p style="color:white;padding-bottom: 5%;"><i style="color:#0AC775;margin-right: 3%;" class="fas fa-phone-alt"></i> 969694020</p>
                    <p style="color:white;padding-bottom: 5%;"><i style="color:#0AC775;margin-right: 3%;" class="fas fa-envelope"></i>adesiman.gal@gmail.com</p>
                </div>
            </div>
        </div>
    </div>
</div>