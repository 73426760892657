import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuscadorPueblosComponent } from './components/buscador-pueblos/buscador-pueblos.component';
import { CacomerComponent } from './components/cacomer/cacomer.component';
import { ContenidocursosComponent } from './components/contenidocursos/contenidocursos.component';
import { ExamenComponent } from './components/examen/examen.component';
import { FormacionComponent } from './components/formacion/formacion.component';
import { HomeComponent } from './components/home/home.component';
import { MenutareasComponent } from './components/menutareas/menutareas.component';
import { NewsComponent } from './components/news/news.component';
import { OfertasTrabajoComponent } from './components/ofertas-trabajo/ofertas-trabajo.component';
import { PerfilDelContratanteComponent } from './components/perfil-del-contratante/perfil-del-contratante.component';
import { ProderComponent } from './components/proder/proder.component';
import { Proyecto14Component } from './components/proyecto14/proyecto14.component';
import { Proyecto7Component } from './components/proyecto7/proyecto7.component';
import { BasededatosComponent } from './components/pruebas/basededatos/basededatos.component';
import { MenuCursosComponent } from './components/pruebas/menu-cursos/menu-cursos.component';
import { MenuJuntaComponent } from './components/pruebas/menu-junta/menu-junta.component';
import { MenuOfertasComponent } from './components/pruebas/menu-ofertas/menu-ofertas.component';
import { MenuProgramasComponent } from './components/pruebas/menu-programas/menu-programas.component';
import { MenuServiciosPerfilComponent } from './components/pruebas/menu-servicios-perfil/menu-servicios-perfil.component';
import { MenuUsuariosComponent } from './components/pruebas/menu-usuarios/menu-usuarios.component';
import { PruebasComponent } from './components/pruebas/pruebas.component';
import { PueblosComponent } from './components/pueblos/pueblos.component';
import { RedcreaComponent } from './components/redcrea/redcrea.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { SobreAdesimanComponent } from './components/sobre-adesiman/sobre-adesiman.component';
import { SubvencionesComponent } from './components/subvenciones/subvenciones.component';
import { TerritoriosComponent } from './components/territorios/territorios.component';
import { AdminGuard } from './services/admin.guard';
import { AuthGuard } from './services/auth.guard';
import { Proyecto23Component } from './components/proyecto23/proyecto23.component';

const routes: Routes = [

{path: '', component: HomeComponent},
{path: "noticias", component: NewsComponent},
{path: "subvenciones", component: SubvencionesComponent},
{path: "formacion", component: FormacionComponent},
{path: "servicios", component: ServiciosComponent},
{path: "el-territorio", component: TerritoriosComponent},
{path: "los-pueblos", component: PueblosComponent},
{path: "programa-proder", component: ProderComponent},
{path: "programa-red-crea-2", component: RedcreaComponent},
{path: "programa-cacomer", component: CacomerComponent},
{path: "proyecto-2014-2021", component: Proyecto14Component},
{path: "proyecto-2023-2027", component: Proyecto23Component},
{path: "proyecto-2007-2013", component: Proyecto7Component},
{path: "busca-en-el-territorio", component: BuscadorPueblosComponent},
{path: "pruebas", component: PruebasComponent, canActivate: [AdminGuard]},
{path: "menu-pueblos", component: BasededatosComponent},
{path: "menu-junta", component: MenuJuntaComponent},
{path: "menu-cursos", component: MenuCursosComponent},
{path: "menu-servicios-perfil", component: MenuServiciosPerfilComponent},
{path: "menu-programas", component: MenuProgramasComponent},
{path: "menu-ofertas", component: MenuOfertasComponent},
{path: "menu-usuarios", component: MenuUsuariosComponent},
{path: "cursos", component: MenutareasComponent, canActivate: [AuthGuard]},
{path: "ofertas-de-trabajo", component: OfertasTrabajoComponent},
{path: "formacion/:id", component: ContenidocursosComponent },
{path: "examen/:idcurso/:id", component: ExamenComponent},
{path: "sobre-adesiman", component: SobreAdesimanComponent},
{path: "perfil-del-contratante", component: PerfilDelContratanteComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
