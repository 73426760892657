import { not } from '@angular/compiler/src/output/output_ast';
import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Noticia } from 'src/app/models/noticia';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-listanoticias',
  templateUrl: './listanoticias.component.html',
  styleUrls: ['./listanoticias.component.css']
})
export class ListanoticiasComponent implements OnInit {
  totalItems: number;
  noticiaslist: Noticia[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.noticiaslist = [];
    
    this.crudsService.getNoticias()
    .snapshotChanges()
    .subscribe(item =>{
      this.noticiaslist = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.noticiaslist.push(x as Noticia);
      });
      this.ordenporNumero(this.noticiaslist);
      this.totalItems = this.noticiaslist.length;
    })
  }

  ordenporNumero(lista: Noticia[]){
    var nuevaLista: Noticia[];
    nuevaLista = [];
    let anio: string;
    anio = new Date().getFullYear().toString();
    anio = anio.substr(2,anio.length)
    var i = lista.length - 1
    var contador = 0;
    while(lista[i].titulo.includes(anio)){
      nuevaLista.push(lista[i])
      var i = lista.indexOf(lista[i])
      lista.splice(i, 1)
      i--;
    }
    for(var i = 0; i < nuevaLista.length; i++){
      lista.unshift(nuevaLista[i])
    }
  }

  onEdit(noticia: Noticia){
    this.crudsService.selectNoticia = Object.assign({},noticia)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar la noticia '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteNoticia($key);
        }
    });
    
  }

}
