import { Component, OnInit } from '@angular/core';
import { JsonserviceService } from 'src/app/services/jsonservice.service';

@Component({
  selector: 'app-subvenciones',
  templateUrl: './subvenciones.component.html',
  styleUrls: ['./subvenciones.component.css']
})
export class SubvencionesComponent implements OnInit {
  imagenes:any[];
  constructor(private json: JsonserviceService) { }

  ngOnInit(): void {
    this.json.getJson('/assets/imagenes.json').subscribe((res:any) => {
      this.imagenes = res;
      document.getElementById("loader2").className = "loader2 animate__animated animate__fadeOut";
        setTimeout(function(){
          document.getElementById("loader2").style.display = "none";
        },1000)
    });
  }

}
