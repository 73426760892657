<app-header></app-header>
<div id = "containerExamen" class="container-fluid" style="padding-bottom: 2em;">
    <div id = "contenedor1" class="container">
        <div class="row">
            <div style="padding-left:2em;width:100%;height: 3em;display: flex;align-items: center;"><i class="fas fa-arrow-left"></i> &nbsp;Volver al curso</div>
        </div>
        <div id = "containerExamen" class = "container-fluid" style="height:90%;">
            <div class="row" style="height:50%;background:#F5F5F5;display: flex;align-items: center;justify-content: center;">
                <div>
                    <div style="text-align:center"><h1 style="font-weight: bold;">Examen final</h1></div>
                    
                    <h3 style="color:#394F69">para el curso de {{this.idExamen}}</h3>
                    <div style="text-align:center"><h1 style="font-weight: bold;"><i class="fas fa-angle-down"></i></h1></div>
                </div>
            </div>
             <div class="row" style="height:50%;background:#FFFFFF;display: flex;align-items: center;justify-content: center;">
                <div style="text-align: center;">
                    <div style="text-align:center"><h3 >Último paso para obtener el Certificado del Curso</h3></div>
                    
                    <span>Recuerda que si superas el examen podrás acceder a los contenidos del curso para siempre</span>
                    <div style="width:100%;display:flex;align-items:center;justify-content:center">
                        <br><br><br><br>
                        <div (click)="empezarExamen()" style="background:#394F69;display: flex;align-items: center;justify-content: center;padding: 0.7em;color:white;cursor:pointer">Comenzar examen</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <br><br>
    <div id = "contenedor2" class="container" style="background:white">
        <div class="row" style="background:#F5F5F5;padding: 0.5em;border-bottom: 1px solid #1c1c1c;">
            <div class="col-md-6">
                <div style="width:100%;">
                    <h4>Examen final</h4>
                    <p>{{this.idExamen}}</p>
               </div>
            </div>
            <div class="col-md-6">
                <div style="width:100%;float:right;margin-top: 4%;">
                    <div style="float: right;font-size: 18px;"><i style="color:#394F69" class="far fa-clock"></i> <span style="font-weight: bold;"> 30 min</span> tiempo restante</div>
               </div>
            </div>
           
        </div>
        <br><br>
        <div style="width:100%;text-align:center;">
            <h2>Pregunta {{this.paginas}} de {{this.preguntas.length}}</h2>
        </div>  
        <br>
        <div style="width:100%;height:1em;background:#394F69"></div>
        <br><br>
        <div class="container" style="max-width: 1000px;text-align: center;">
            <h3>{{this.preguntasexamen["p"]}}</h3>
            <br>
            <div class="row" style="font-weight: 500;">
                <div class="col-md-6">
                    <div style="height:90%;width:100%;display: flex;align-items: center;
                    justify-content: center;background:#F5F5F5;border:1px solid black;padding: 1em;">
                        <input (click)="checkRadio('a')" type="radio" id="a" name="drone" value="dewey">
                        <label for="dewey">{{this.preguntasexamen["a"]}}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="height:90%;width:100%;display: flex;align-items: center;
                    justify-content: center;background:#F5F5F5;border:1px solid black;padding: 1em;">
                        <input (click)="checkRadio('b')" type="radio" id="b" name="drone" value="dewey">
                        <label for="dewey">{{this.preguntasexamen["b"]}}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="height:90%;width:100%;display: flex;align-items: center;
                    justify-content: center;background:#F5F5F5;border:1px solid black;padding: 1em;">
                        <input (click)="checkRadio('c')" type="radio" id="c" name="drone" value="dewey">
                        <label for="dewey">{{this.preguntasexamen["c"]}}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="height:90%;width:100%;display: flex;align-items: center;
                    justify-content: center;background:#F5F5F5;border:1px solid black;padding: 1em;">
                        <input (click)="checkRadio('d')" type="radio" id="d" name="drone" value="dewey">
                        <label for="dewey">{{this.preguntasexamen["d"]}}</label>
                    </div>
                </div>
            </div>
            
        </div>
        <div style="width: 100%;display: flex;align-items: center;justify-content: center;padding-top: 1em;">
            <div (click)="next()" style="background:#394F69;display: flex;align-items: center;justify-content: center;padding: 0.7em;color:white;cursor:pointer;width:20%">Responder y seguir</div>
        </div>
        <br>
        <div style="display: flex;align-items: center;justify-content: center;" class = "container-fluid">
            <p id = "errorPreguntas"></p>
        </div>

        
        
    </div>
    <div id = "container3" class = "container" style="height:90%;">
        <div class="row" style="height:50%;background:#F5F5F5;display: flex;align-items: center;justify-content: center;">
            <div>
                <br>
                <div style="text-align:center"><h1 style="font-weight: bold;">Resultados</h1></div>
                
                <h3 style="color:#394F69">para el curso de {{this.idExamen}}</h3>
                <div style="text-align:center"><h1 style="font-weight: bold;"><i class="fas fa-angle-down"></i></h1></div>
            </div>
        </div>
         <div class="row" style="height:50%;background:#FFFFFF;display: flex;align-items: center;justify-content: center;">
            <div class = "container-fluid" *ngIf = "listallena">
                <div *ngFor = "let result of listaResults">
                    <div *ngIf = "result.correcto === 'true'" class="row">
                        <div class="col-lg-6" style="height:3em;display: flex;align-items: center;justify-content: center;">
                            <p style="margin-top:2.5%">{{result.pregunta}}</p>
                        </div>
                        <div class="col-lg-6" style="height:3em;background:rgb(250, 250, 250);display: flex;align-items: center;justify-content: center;">
                            <p style="margin-top:2.5%"><i class="pi pi-check" style="font-size: 20px"></i> CORRECTO</p>
                        </div>
                    </div>
                    <div *ngIf = "result.correcto === 'false'" class="row">
                        <div class="col-lg-6" style="height:3em;display: flex;align-items: center;justify-content: center;">
                            <p style="margin-top:2.5%">{{result.pregunta}}</p>
                        </div>
                        <div class="col-lg-6" style="height:3em;background:rgb(250, 250, 250);display: flex;align-items: center;justify-content: center;">
                            <p style="margin-top:2.5%"><i class="pi pi-times" style="font-size: 20px"></i> INCORRECTO</p>
                        </div>
                    </div>
                </div>
                <br>
                <div style="display: flex;align-items: center;justify-content: center;" class="container">
                    <p id = "resultadoExamen"></p>
                </div>
            </div>
        </div>
    </div>
 
</div>
<app-footer></app-footer>

