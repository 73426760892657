<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Proyecto CACOMER</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class="container" style="padding-bottom: 5%;">
    <div class="row" style="padding-top: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <p style="font-size: 20px;">Proyecto CACOMER</p>
    </div>
    <div class="container" style="padding: 0;"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <div class="row" style="margin-bottom: 2%;">
            <div class = "col-lg-5" style="height:8em;background-image: url('/assets/descarga.png');background-size: 100% 100%;"></div>
        </div>
        <div class="row">
            <p style="font-size:20px">Cacomer</p>
            <p>CACOMER: El proyecto que desarrolla el Grupo Operativo Canales Cortos de Comercialización (CACOMER). 
                Es un proyecto encuadrado dentro de la convocatoria efectuada por el Ministerio de Agricultura, Alimentación y Medioambiente, 
                mediante el Real Decreto 253/2016, de 10 de junio, por el que se establecen las bases reguladoras para la concesión de subvenciones
                 para la creación de grupos operativos supraautonómicos en relación con la Asociación Europea para la Innovación en materia de productividad y 
                 sostenibilidad agrícolas.
            </p>
            <ul>
                <li>
                    <a style="color:#5256B3" class="linkClass" (click)="goTo('https://adesiman.org/otrosprogramas/canales%20comercializa%20presentaci%C3%B3n1.pdf')">CANALES COMERCIALIZADORES PRESENTACION</a>
                </li>
                <li>
                    <a style="color:#5256B3" class="linkClass" (click)="goTo('https://adesiman.org/otrosprogramas/ENCUESTA%20productores%20y%20comercializadores.pdf')">ENCUESTA PRODUCTORES Y COMERCIALIZADORES</a>
                </li>
                <li>
                    <a style="color:#5256B3" class="linkClass" (click)="goTo('https://adesiman.org/otrosprogramas/MARCO%20DE%20LA%20ENCUESTA_3.pdf')">MARCO DE LA ENCUESTA</a>
                </li>
                <li>
                    <a style="color:#5256B3" class="linkClass" (click)="goTo('https://es.surveymonkey.com/r/calidad_alimentos_redes')"><i class="fas fa-poll-h"></i> ENCUESTA ONLINE</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<app-footer></app-footer>