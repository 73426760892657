

<!-- ESTE ES EL MENU DE NOTICIAS -->
<div style="height: 100%;">
    
    <div class="container-fluid" style="background:#E1F1F5;height: 100%;padding:0;">
       
            <app-header-admin></app-header-admin>
            <div style="padding-left: 5%;padding-right: 5%;padding-top: 5%;">  
            <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
            <p style="font-size: 20px;">Menu para añadir o editar noticias</p>
            <div class="row">
                <div class="col-4" style="padding-top: 1%;">
                    <form #noticiasForm="ngForm" (ngSubmit)="onSubmit(noticiasForm)">
                        <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectNoticia.$key">
                        <!-- AQUI SE ESCRIBE EL TITULO -->
                        <input name="titulo" 
                        type="text" 
                        class="inputclass" 
                        #name="ngModel" 
                        [(ngModel)]="crudsService.selectNoticia.titulo"
                        placeholder="Titulo de la noticia"
                        >
                        
                        <!-- AQUI VA LA DIRECCION DE LA IMAGEN, tipo: '/assets/nombredeimagen.jpg' -->
                        
                        <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                          <div class="label" fxFlex="30">IMAGEN</div>
                          <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/><button mat-flat-button color="primary" (click)="uploadImage()">Cargar</button></div>
                          <div fxFlex="30">
                            
                            <div class="label" fxFlex="30" *ngIf="completed">Archivo cargado</div>
                          </div>
                        </section>

                        
                        <input name="imagen" 
                        id = "imagen"
                        type="text" 
                        class="inputclass" 
                        #name="ngModel" 
                        [(ngModel)]="crudsService.selectNoticia.imagen"
                        placeholder="Imagen de la noticia"
                        >

                        <!-- AQUI VA EL PDF -->

                        <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                          <div class="label" fxFlex="30">PDF</div>
                          <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/><button mat-flat-button color="primary" (click)="uploadPDF()">Cargar</button></div>
                          <div fxFlex="30">
                            
                            <div class="label" fxFlex="30" *ngIf="completed">Archivo cargado</div>
                          </div>
                        </section>
                        <input name="url" 
                        type="text" 
                        class="inputclass" 
                        #name="ngModel" 
                        [(ngModel)]="crudsService.selectNoticia.url"
                        placeholder="Selecciona un pdf"
                        >
                        <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                            Agregar
                        </button>
                        <button class="btn btn-secondary" type="reset"(click)="resetForm(noticiasForm)">
                            Limpiar
                        </button>
                    </form>
                </div>
                <div class="col-6" style="background: white;">
                    
                    <app-listanoticias></app-listanoticias>
                </div>
                
                
            </div>
        </div>
        
    </div>
</div>

