import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Curso } from 'src/app/models/curso';
import { Examen } from 'src/app/models/examen';
import { Pregunta } from 'src/app/models/pregunta';
import { Tarea } from 'src/app/models/tarea';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-cursos',
  templateUrl: './lista-cursos.component.html',
  styleUrls: ['./lista-cursos.component.css']
})
export class ListaCursosComponent implements OnInit {
  totalItems: number;
  cursosList: Curso[];
  examenesList: Examen[];
  lista: string[];
  listadePreguntas: Pregunta[] = new Array<Pregunta>();
  pregunta: Pregunta = new Pregunta();
  constructor(public crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  @Output()
  propagar = new EventEmitter<Curso>();
  ngOnInit(): void {

    this.cursosList = [];
    
    this.crudsService.getCursosO()
    .snapshotChanges()
    .subscribe(item =>{
      this.cursosList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.cursosList.push(x as Curso);
      });
      this.totalItems = this.cursosList.length;
    })

    this.examenesList = [];
    this.crudsService.getExamenesO()
    .snapshotChanges()
    .subscribe(item =>{
      this.examenesList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.examenesList.push(x as Examen);
      });
    })

  }

  onEdit(curso: Curso){
    this.crudsService.selectCurso = Object.assign({},curso)
    this.propagar.emit(curso)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el curso '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteCurso($key);
        }
    });
    
  }

idCurso: string
examenesByCourse: Examen[] = new Array<Examen>();
examen: Examen = new Examen();
addExam(key: any, id: string){
  this.idCurso = id
  document.getElementById("colcursos").style.display = "none"
  document.getElementById("colcrearexamen").style.display = "block"
  for(var i = 0; i < this.examenesList.length; i ++){
    if(this.examenesList[i].id == id)
     this.examenesByCourse.push(this.examenesList[i])
  }   
  console.log(this.examenesByCourse)
}

closeExamMenu(){
  this.examenesByCourse = new Array<Examen>();
  this.listadePreguntas = new Array<Pregunta>();
  this.crudsService.selectExamen.nombre = "";
  this.pregunta = new Pregunta();
  document.getElementById("colcrearexamen").style.display = "none"
  document.getElementById("colcursos").style.display = "block"
  document.getElementById("erroresnombre").innerHTML = ""
  document.getElementById("errorespreguntas").innerHTML = ""
  document.getElementById("successexamen").innerHTML = ""
}

anadirPreguntas(){
  if(this.crudsService.selectExamen.nombre == "" || this.crudsService.selectExamen.nombre == undefined){
    document.getElementById("erroresnombre").innerHTML = "Debes seleccionar un nombre para el examen"
  }else{
    document.getElementById("erroresnombre").innerHTML = ""
  }
}

addpreguntaToList(){
  if(this.pregunta.p == undefined || this.pregunta.p == "" || this.pregunta.a == undefined || this.pregunta.a == "" || 
     this.pregunta.b == undefined || this.pregunta.b == "" || this.pregunta.c == undefined || this.pregunta.c == "" ||
     this.pregunta.d == undefined || this.pregunta.d == "" || this.pregunta.respuesta == undefined || this.pregunta.respuesta == ""
  ){
    document.getElementById("errorespreguntas").innerHTML = "Debes rellenar todos los campos"
  }else{
    document.getElementById("errorespreguntas").innerHTML = ""
    this.listadePreguntas.push(this.pregunta)
    this.pregunta = new Pregunta()
  }
}

crearExamen(){
  if(this.listadePreguntas.length == 0){
    document.getElementById("erroresexamen").innerHTML = "Debes añadir por lo menos una pregunta"
  }else{
    var examen = new Examen();
    examen.id = this.idCurso
    examen.nombre = this.crudsService.selectExamen.nombre;
    examen.preguntas = this.listadePreguntas
    console.log(examen)
    this.crudsService.insertExamen(examen)
    this.examenesByCourse.push(examen)
    document.getElementById("successexamen").innerHTML = "Examen creado correctamente"
    this.listadePreguntas = new Array<Pregunta>()
  }
}

deletexam(examen: Examen){
  this.confirmationService.confirm({
    message: 'Estas seguro de que quieres eliminar el curso '+ examen.nombre + "?",
    accept: () => {
      var index = this.examenesByCourse.indexOf(examen)
      this.examenesByCourse.splice(index, 1)
      this.crudsService.deleteExamen(examen.$key);
    }
});

}



}
