import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-sobre-adesiman',
  templateUrl: './sobre-adesiman.component.html',
  styleUrls: ['./sobre-adesiman.component.css']
})
export class SobreAdesimanComponent implements OnInit {

  constructor(private crudsService: CrudsService) { }
  public junta: any[];

  ngOnInit(): void {
    this.crudsService.getJunta().subscribe((res: any) => {
      this.junta = res;
    })
  }

}
