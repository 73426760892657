<table class="table table-sm table-hover">
    <tbody>
        <div class = "col-lg-12" id = "colcursos" >
            <span style="font-size: 20px;">Lista de cursos</span><span style="float: right;">cursos totales: {{totalItems}}</span>
            <div id = "content" style="overflow-y: scroll;max-height:200px;width: 100%;">
                <div id = "divcontent" *ngFor="let curso of cursosList">
                    <td style="">
                        <span>Nombre del curso</span> - {{curso.id}} <br> 
                        <span> Direccion de la imagen </span> - {{curso.imagen}} <br>
                    </td>
                    <button (click)="onEdit(curso)" style="margin-right: 1%;" class = "btn btn-secondary text-white"><i class="far fa-edit"></i></button>
                    <button (click)="onDelete(curso.$key, curso.id)" class = "btn btn-danger text-white"><i class="far fa-trash-alt"></i></button>   
                    <button style="margin-left:0.7em" (click)="addExam(curso.$key, curso.id)" class = "btn btn-info text-white">Añadir examen</button>      
                </div>
            </div>
            <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>
        </div>
        <div class = "col-lg-12" id = "colcrearexamen">
           <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6"><p>Añade un examen para el curso {{this.idCurso}}</p></div>
                <div id = "divexamenesicon" class="col-lg-6 col-md-6 col-sm-6 col-6"><i (click)="closeExamMenu()" id = "icon" class="far fa-times-circle" ></i></div>
                    <div class="col-lg-4">
                            <input name="nombre" 
                            id = "nombre"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="crudsService.selectExamen.nombre"
                            placeholder="Nombre del examen"
                        />
                        <button (click)="anadirPreguntas()"  class = "btn btn-info text-white">Añadir preguntas al examen</button> 
                        <br><br>
                        <p style="background:rgb(197, 115, 115);color:white;max-width: 60%;" id = "erroresnombre"></p>
                        
                        <div>
                            <p>Lista de examenes del curso {{this.idCurso}}</p>
                            <div *ngFor = "let examen of examenesByCourse" style="margin-bottom:0.5em;background:white;max-width: 30%; display: flex;align-items: center;justify-content: center;">
                                <span>{{examen.nombre}}&nbsp;</span>
                                <i style = "cursor:pointer;" (click)="deletexam(examen)" _ngcontent-rib-c74="" class="far fa-trash-alt" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" id = "colpreguntas">
                            <input name="pregunta" 
                            id = "nombre"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.p"
                            placeholder="Escribe la pregunta"
                            />
                            <input name="resp1" 
                            id = "nombre1"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.a"
                            placeholder="Escribe la respuesta A"
                            />
                            <input name="resp2" 
                            id = "nombre2"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.b"
                            placeholder="Escribe la respuesta B"
                            />
                            <input name="resp3" 
                            id = "nombre3"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.c"
                            placeholder="Escribe la respuesta C"
                            />
                            <input name="resp4" 
                            id = "nombre4"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.d"
                            placeholder="Escribe la respuesta D"
                            />

                            <input name="resp4" 
                            id = "nombre4"
                            type="text" 
                            class="inputclass" 
                            #name="ngModel" 
                            [(ngModel)]="this.pregunta.respuesta"
                            placeholder="Escribe la respuesta a la pregunta (A, B, C, D)"
                            />



                        <p style="background:rgb(197, 115, 115);color:white;max-width: 60%;" id = "erroresnombre"></p>
                        <button (click)="addpreguntaToList()"  class = "btn btn-info text-white">Añadir pregunta</button> 
                        <br><br>
                        <p id = "errorespreguntas"></p>
                    </div>
                    <div class="col-lg-4">
                        <p>Lista de preguntas del examen</p>
                        <div class = "row" >
                            <div class="col-lg-6" style="background:white;border:0.5px solid black;margin-bottom: 0.2em;" *ngFor = "let pregunta of listadePreguntas">
                                <span>Pregunta - {{pregunta.p}}</span><br>
                                <span>Respuesta - {{pregunta.respuesta}}</span>
                            </div>
                        </div>
                        <br>
                        <button (click)="crearExamen()"  class = "btn btn-info text-white">Crear examen</button> 
                        <br><br>
                        <p id = "erroresexamen"></p>
                        <p id = "successexamen"></p>
                    </div>
                    <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>

                </div>
        </div>
    </tbody>
</table>
       
  