import { Component, OnInit } from '@angular/core';
import { JsonserviceService } from 'src/app/services/jsonservice.service';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private json: JsonserviceService, private crudsService: CrudsService) { }
  public noticiaslist: any[];
  public imagenes: any[];
  public noticiasFoot: any[];

  ngOnInit(): void {
    this.json.getJson('/assets/imagenes.json').subscribe((res:any) => {
      this.imagenes = res;
      document.getElementById("loader2").className = "loader2 animate__animated animate__fadeOut";
        setTimeout(function(){
          document.getElementById("loader2").style.display = "none";
        },1000)
    });

    this.crudsService.getNoticiasO().subscribe((res: any) => {
      this.noticiaslist = res
       this.imagenes = this.ordenarLista(this.noticiaslist);
     })

    
  }


   ordenarLista(data: any[]): any[]{
     let nums = [];
     for(var i = data.length - 1; i >=0; i--){
        if(nums.length < 3){
          nums.push(data[i])
        }
     }
     return nums;
   }

  goTwitter(){
    location.href ='https://twitter.com/ADESIMAN_';
  }

  goTo(data: any){
    window.open(data)
  }

}
