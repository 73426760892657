<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12" style="display:flex;align-items:center;justify-content: center;"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Formacion online</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div _ngcontent-aqi-c45="" class="container-fluid" style="background: #FCFCFC;"><div _ngcontent-aqi-c45="" class="container" style="display: flex; align-items: center; padding-top: 1em; justify-content: center;"><p _ngcontent-aqi-c45=""><i _ngcontent-aqi-c45="" class="far fa-bookmark" aria-hidden="true"></i> &nbsp;Si eres alumno, puedes acceder a nuestros cursos a continuación:</p></div></div>

<div class = "container" style="padding:3em;min-height: 25em;">
    <br>
    <div class="row"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
    
            <div  *ngFor = "let curso of listacursos" class="col-md-4">
                <div id = "contenedorcurso" style="width:100%;background:white">
                    <div data-aos="fade-in" data-aos-delay="300" data-aos-duration="800" routerLink="/formacion/{{curso.id}}" style="background-image: url({{curso.imagen}}); background-size: 100% 100%; height: 8em;"></div>
                <br _ngcontent-aqi-c45="">
                <div _ngcontent-aqi-c45="" style="padding: 0.3em; border-top: 2px solid #394F69; border-bottom: 2px solid #394F69; text-align: center; color: #394F69; font-weight: 600;">Curso de {{curso.id}}</div>
                </div>
            </div>

    </div>
</div>
<br>
<app-footer></app-footer>