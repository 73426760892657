<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Perfil del contratante</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<br><br>
<div class="container" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
    <p _ngcontent-mgt-c47="" style="font-size: 20px;">Servicios de Adesiman</p>
    <span _ngcontent-mgt-c47="" style="display: block;margin-bottom: 1em;">Documentación de interés:</span>
    <div *ngFor = "let contratado of this.contratados" class="col-lg-4 col-md-6" style="max-width: 400px;">
      <div style="margin-bottom:0.5em">
        <i _ngcontent-mgt-c47="" class="far fa-file" aria-hidden="true" style="margin-right:10px"></i>
        <a style="color:#2870BF;" class = "linkClass" (click)="goTo(contratado.direccion)">{{contratado.nombre}}</a>
      </div>
    </div>
</div>
<br><br>
<app-footer></app-footer>

