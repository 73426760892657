import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-ofertas-trabajo',
  templateUrl: './ofertas-trabajo.component.html',
  styleUrls: ['./ofertas-trabajo.component.css']
})
export class OfertasTrabajoComponent implements OnInit {

  constructor(private crudsService: CrudsService) { }
  public ofertas: any[];
  ngOnInit(): void {

    this.crudsService.getOfertas().subscribe((res: any) => {
      this.ofertas = res
    })
  }

  goTo(data: any){
    window.open(data)
  }
}
