import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestras-noticias',
  templateUrl: './nuestras-noticias.component.html',
  styleUrls: ['./nuestras-noticias.component.css']
})
export class NuestrasNoticiasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
