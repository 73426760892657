import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate{


    constructor(private auth: AngularFireAuth, private router: Router){}
    user = this.auth.authState.pipe(map(authState => {
        if(authState){
          return authState;
        }
    }))
      
    admin: boolean
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        
        this.auth.onAuthStateChanged((user) =>{
            if(user.email == 'administrador@adesiman.org'){
              this.admin = true
            }
          })
        return this.admin
    }
}