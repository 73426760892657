<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                   
                    <span style="font-size:70px">Sobre adesiman</span>
                </div>
               
            </div>
        </div>
    </div>
</div>
<div class = "container" style="padding: 3%;padding-left: 0;">
    <div class = "row">
        <div class = "col-12" style="margin-bottom: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p id ="title">Presentacion</p>
            <p>La "Federación para el Desarrollo de la Sierra y Mancha Conquense" (ADESIMAN) es un Grupo de Desarrollo Rural. ADESIMAN carece de ánimo de lucro y se constituyó el 7 de enero de 1997 en Asamblea General de sus socios fundadores.

                Actualmente, ADESIMAN es el resultado de la Federación de dos Asociaciones: la Asociación para el Desarrollo de los Municipios de la Mancha Alta Conquense (ADIMMAC),y la Asociación Serranía Deprimida Cuenca Centro (SEDECUCE).</p>
        
            <div id = "rowImagenes" class="row">
                <div id="img1" class="col-lg-2 col-md-2 col-sm-2 col-4" style="margin-right: 2em; height: 100%; background: green; background-image: url('../../../assets/Adesiman.jpg'); background-size: 100% 100%;"></div>
                <div id="img2" class="col-lg-2 col-md-2 col-sm-2 col-4" style="height: 100%; background: rgb(43, 59, 43); background-image: url('../../../assets/sedecuce.jpg'); background-size: 100% 100%;"></div>
            </div>
            <br>
            <p>Estas asociaciones carecen de ánimo de lucro y su fin social es el desarrollo de sus respectivos territorios de actuación. En ADESIMAN se integran, a través de ADIMMAC y SEDECUCE, un total de 56 ayuntamientos, y un gran numero de asociaciones (juveniles, culturales, de mujeres, de la tercera edad, de empresarios), así como cooperativas y organizaciones agrarias, empresas, y un elevado número de profesionales autónomos y personas físicas, entre otros. Desde su constitución, ADESIMAN ha venido trabajando por el desarrollo local dentro de su comarca de intervención. Pese a ser una entidad joven, la experiencia acumulada en estos más de diez años, permite hablar de una entidad sólida en base a su conocimiento del terreno y de la población, a haber aglutinado a los representantes sociales, políticos y económicos, 
                y a haber obtenido unos resultados importantes en la gestión de los Programas PRODER 1 y PRODER 2.</p>
        
        </div>
        
        <div class = "col-12" style="margin-bottom: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p id ="title">Fines y objetivos</p>
            <p>La experiencia de Adesiman en el diseño y gestión de programas de desarrollo puede evaluarse a un doble nivel. Por una parte, teniendo en cuenta su trayectoria como GDR, por otra, considerando la experiencia de los socios integrantes de la Federación en materia de desarrollo rural. 
                A continuación se recogen los principales proyectos en los que han participado cada una de ellas:</p>
                <ul _ngcontent-mgt-c59=""><br _ngcontent-mgt-c59=""><p _ngcontent-mgt-c59="" style="font-weight: 600;">Federación ADESIMAN</p><li _ngcontent-mgt-c59="">Diseño, gestión y ejecución del Programa Operativo Proder-1 (1997-2001)</li><li _ngcontent-mgt-c59="">Diseño, gestión y ejecución del Programa Operativo Proder-2 (2002-2007)</li><li _ngcontent-mgt-c59="">Participación en el Plan Estratégico de Formación para la Inserción Laboral de la Mujer en Zonas Desfavorecidas (FASIL)</li></ul>
                <ul _ngcontent-mgt-c59=""><br _ngcontent-mgt-c59=""><p _ngcontent-mgt-c59="" style="font-weight: 600;">Asociación ADIMMAC</p><li _ngcontent-mgt-c59="">Promotor del Programa LEADER II de Adquisición de Capacidades de los municipios de la Mancha Alta Conquense.</li><li _ngcontent-mgt-c59="">Participación en la gestión y ejecución del Programa Proder-1 de Adesiman.</li><li _ngcontent-mgt-c59="">Participación en la gestión y ejecución del Programa Proder-2 de Adesiman.</li><li _ngcontent-mgt-c59="">Implantación y puesta en marcha de las Agendas21 Locales de sus municipios, a través de la elaboración de la Agenda21 Comarcal de la Mancha Alta</li></ul>
                <ul _ngcontent-mgt-c59=""><br _ngcontent-mgt-c59=""><p _ngcontent-mgt-c59="" style="font-weight: 600;">Asociación SEDECUCE</p><li _ngcontent-mgt-c59="">Promotor del Programa LEADER II de Adquisición de capacidades de la Serranía Deprimida de Cuenca Centro.</li><li _ngcontent-mgt-c59="">Gestión y ejecución del Programa LEADER II de Adquisición de Capacidades de la Serranía Deprimida de Cuenca Centro.</li><li _ngcontent-mgt-c59="">Participación en la gestión y ejecución del Programa Proder-1 de Adesiman.</li><li _ngcontent-mgt-c59="">Participación en la gestión y ejecución del Programa Proder-2 de Adesiman.</li><li _ngcontent-mgt-c59="">Implantación y puesta en marcha de las Agendas21 Locales de sus municipios, a través de la elaboración de la Agenda21 Comarcal de la Sierra Media</li></ul>
            </div>
        </div>
    <div class = "col-12" style="margin-bottom: 5%;">
        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600" id ="title">Implantación territorial de ADESIMAN</p>
        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600" _ngcontent-mgt-c59="" style="font-weight: 600;">Representatividad</p>
        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">Como ya se señaló el GDR está compuesto por 2 Asociaciones, de ámbito territorial [ADIMMAC y SEDECUCE]. Las asociaciones territoriales, 
            están integradas en gran parte por personas jurídicas y por tanto, socios colectivos. En concreto, representan a 53 de los 56 municipios 
            sobre los que se extiende el territorio, a 62 asociaciones, a 12 cooperativas y a 4 asociaciones de empresarios. Estas últimas entidades colectivas son, 
            además, altamente representativas del territorio pues las 62 asociaciones agrupan a más de 7.500 habitantes y las 12 cooperativas a 132 socios. Hay que destacar que, 
            de las asociaciones, dos son de empresarios [CEOE-CEPYME Y ALCAD], y dos son organizaciones profesionales agrarias [ASAJA y UPA]. 
            La amplia presencia de este tipo de socios incide directamente en la mayor implantación territorial del GDR.</p>

        <br>
        <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">Cabe señalar que en los últimos años el GDR ADESIMAN ha aumentado considerablemente su implantación territorial. En concreto, 
            se han incorporado al territorio 6 nuevos municipios respecto a periodos de programación anteriores: dos de ellos, Belinchón y Zarza de Tajo, 
            procedentes del GDR CEDER ALCARRIA CONQUENSE, y cuatro, Fuente de Pedro Naharro, El Acebrón, Horcajo de Santiago y Pozorrubio, procedentes del antiguo ASPAD-14. 
            También se ha ampliado el número de representantes de la sociedad civil y económica. En concreto, se han incorporado al GDR de manera activa: 3 nuevas cooperativas agrarias, 
            11 nuevas Asociaciones socio-culturales en su mayoría pero también agrarias, de empresarios, y una Sociedad Limitada. El aumento de presencia de este tipo de socios, 
            cooperativas y asociaciones agrarias y de empresarios, 
            implica un elevado incremento de la participación comarcal en las actividades y decisiones de ADESIMAN.</p>

        <br>
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p  _ngcontent-mgt-c59="" style="font-weight: 600;">Dinamización del territorio</p>
            <p  _ngcontent-mgt-c59="">El proceso de dinamización llevado a cabo por el GDR ha contribuido a la puesta en marcha de experiencias que han dado lugar al surgimiento de distintas asociaciones, entre ellas: </p>
            <ul _ngcontent-mgt-c59=""><li _ngcontent-mgt-c59="">El Centro de iniciativas turísticas de la mancha alta y la sierra media (CITURMAS), en el sector del turismo rural. Cuenta con un importante número de socios que realizan acciones de promoción conjunta </li><li _ngcontent-mgt-c59=""> La Asociación de productores agroalimentarios de la mancha alta conquense (APAMAC), en el sector agroalimentario. Surgida al amparo de una experiencia piloto en cultivos alternativos. Actualmente se halla en proceso de revisión de objetivos. </li><li _ngcontent-mgt-c59=""> La Asociación de empresarios de la madera de Valera de Abajo (AMEVAL), en el sector de la transformación, distribución y comercialización de la madera. Agrupa a empresarios del sector y facilita estrategias mutuamente beneficiosas en relación con la promoción de la madera. </li></ul>
            <p _ngcontent-mgt-c59=""> En el ámbito de la comercialización agroalimentaria, si bien todavía no se ha constituido ninguna asociación, Adesiman ha participado en la consecución de la Denominación de Origen de Uclés para el vino; asimismo ha promovido actuaciones con empresarios del sector, tales como la edición de folletos y la asistencia a ferias agroalimentarias. </p>
            <p _ngcontent-mgt-c59=""> En el ámbito del turismo rural, sector estratégico para el desarrollo de nuestras comarcas, se ha realizado un importante esfuerzo de asesoramiento e información continua a los empresarios de establecimientos, para conseguir una optima puesta en marcha y gestión de sus negocios (formación desde el nivel de la decoración y distribución de los inmuebles, hasta la gestión contable, administrativa y de funcionamiento diaria). Cabe destacar la realización del Plan de Marketing Turístico de la Mancha Alta Conquense) </p>
            <p _ngcontent-mgt-c59=""> En el ámbito de las cooperativas, se ha potenciado y asesorado su creación (tanto de cooperativas agrarias, como del sector servicios, en especial dirigidas a la creación de empleo para mujeres en servicios a la tercera edad y a la infancia), y mejora del funcionamiento de las cooperativas existentes, con planes de formación específicos. </p>
            <p _ngcontent-mgt-c59=""> En los últimos años se ha abierto un nuevo nicho de formación y de fomento de creación de colaboraciones y cooperaciones, en el sector cinegético, dirigido a los cotos de caza del territorio, si bien esta línea de trabajo todavía no ha dado los frutos esperados, a pesar del importante esfuerzo dinamizador invertido. </p>
            <p _ngcontent-mgt-c59=""> La participación de las Entidades Locales aportando propuestas para el desarrollo integral y sostenible del territorio, así como para la gobernanza del mismo, tiene su máxima expresión en la gran tarea que ha supuesto en los últimos años la implantación de las Agendas 21. La elaboración de los diagnósticos y los Planes de Acción, a nivel comarcal y con el apoyo del GDR primero, y más tarde a nivel local. Se ha conseguido mediante una metodología dinámica, de participación ciudadana activa, de colaboración de todos los agentes de la comarca (empresa, colectivos sociales, culturales,…), en las estrategias y políticas a seguir en sus pueblos. El esfuerzo dinamizador ha sido enorme, y debe continuar con el uso de los Planes de Acción Local como instrumentos rectores de la actividad municipal, y la labor de los Consejos Locales de Sostenibilidad, cuya creación avanza, aunque con ritmo desigual entre los distintos Ayuntamientos. </p>
        </div>
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">       
            <p _ngcontent-mgt-c59=""> Se observa que, a pesar del esfuerzo realizado por el GDR (edición de folletos divulgativos, anuncios y programas en diferentes medios de comunicación, revista de ámbito comarcal, etc..) desde su creación en 1997, en cuanto a la difusión de la labor del grupo y de los sucesivos programas operativos que ha gestionado, la población del territorio no conoce plenamente las posibilidades que la ejecución de un programa de desarrollo territorial ofrece. </p>
            <p _ngcontent-mgt-c59=""> El método más efectivo para dar a conocer al Grupo y su labor, ha sido la presencia física en los municipios, en jornadas, reuniones, visitas de promotores, y las publicaciones específicas de los distintos ámbitos de actuación del Programa, si bien se ha detectado que este sistema no alcanza a la mayoría de la población del territorio, el GDR se plantea la necesidad de buscar nuevos cauces de difusión como: </p>
            <ul _ngcontent-mgt-c59=""><li _ngcontent-mgt-c59="">Potenciar la interacción de la población con el Grupo mediante la utilización de nuevas tecnologías como el establecimiento de Redes, a través de la pagina Web, etc</li><li _ngcontent-mgt-c59="">Uso de los medios de comunicación locales, como radios locales, televisiones locales, etc..</li></ul>
            <p _ngcontent-mgt-c59="" id="title">Implantación territorial de ADESIMAN (ll)</p>
            <p _ngcontent-mgt-c59="">El trabajo en red ha sido considerado como una prioridad por parte del GDR ADESIMAN. La posibilidad de compartir experiencias, conocer otros territorios con problemas similares, la transferibilidad de iniciativas y la sinergia del trabajo en común, han llevado a ADESIMAN a trabajar en la creación y consolidación de redes de desarrollo rural. Concretamente ADESIMAN es socio fundador de las siguientes redes: </p>
            <p _ngcontent-mgt-c59="" style="font-weight: 600;">Federación de Desarrollo Rural de Castilla La Mancha. RECAMDER.</p>
            <p _ngcontent-mgt-c59="">Esta red se constituyó el 29 de enero de 1998, siendo ADESIMAN uno de sus socios fundadores. Su ámbito de actuación es la Comunidad Autónoma de Castilla-la Mancha.</p>
            <p _ngcontent-mgt-c59="">Según el Artículo 4 de sus estatutos, “La Federación tiene como fin fundamental el desarrollo del medio rural, mediante la coordinación de los distintos agentes e instituciones intervinientes y la promoción y potenciación de medidas encaminadas a favorecer ese desarrollo, organizando cuantas actividades se estimen pertinentes, con el único límite de la legalidad vigente.” </p>
            <p _ngcontent-mgt-c59="" style="font-weight: 600;">Federación Red Estatal de Desarrollo Rural. REDER.</p>
            <p _ngcontent-mgt-c59="">Esta Red se constituyó el 20 de diciembre de 1997. Entre sus objetivos fundamentales se encuentran facilitar al GDR:</p>
            <ul _ngcontent-mgt-c59=""><li _ngcontent-mgt-c59="">La posibilidad de formar parte de una entidad de carácter nacional, con fuerza y representación ante distintas instancias públicas nacionales y comunitarias</li><li _ngcontent-mgt-c59="">Contar con una estructura de respaldo y apoyo</li><li _ngcontent-mgt-c59="">El impulso al desarrollo rural</li></ul>
        </div>
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p _ngcontent-mgt-c59="" id="title">Organos responsables del funcionamiento de la entidad y del programa territorial</p>
            <p _ngcontent-mgt-c59="">Los órganos responsables del funcionamiento de la Federación, son la Asamblea General (Órgano de Gobierno) y la Junta Directiva (Órgano de Representación).</p>
            <p _ngcontent-mgt-c59="">La Federación, junto con los órganos responsables del funcionamiento, cuenta con las Comisiones de Desarrollo Territoriales para la ejecución del Programa Territorial.</p>
            <p _ngcontent-mgt-c59="" style="font-weight: 600;">La Asamblea General</p>
            <p _ngcontent-mgt-c59="">Es la máxima autoridad de la Federación Adesiman. Esta estará formada por los representantes designados por SEDECUCE y ADIMMAC, en el número máximo de 20 por cada una de ellas.</p>
            <p _ngcontent-mgt-c59="" style="font-weight: 600;">La Junta Directiva</p>
            <p _ngcontent-mgt-c59="">La Junta Directiva estará compuesta como máximo por 19 miembros. La Asociación Serranía Deprimida Cuenca Centro (SEDECUCE) podrá contar hasta 9 representantes y 10 de la Asociación para el Desarrollo Integral de los Municipios de la Mancha Alta Conquense (ADIMMAC). Actualmente los cargos están ocupados por las siguientes personas:</p>
        </div>
        <div _ngcontent-mgt-c59="" class="row" style="height: 2em; background: #6C7E84; padding: 0em; color: white;">
            <div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%;font-size: 13px;">Foto</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%;font-size: 13px;">Cargo</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-3" style="height: 100%; font-size: 13px;">Titular</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-3" style="height: 100%; font-size: 13px;">Representación</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%; font-size: 13px;">Asociación</div>
        </div>
        <div *ngFor = "let info of this.junta" class="row" style="padding: 1.3em; border-bottom: 1px solid #6C7E84; display: flex; align-items: center; justify-content: center;">
            <div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%; font-size: 13px; text-align: center;">
                <img _ngcontent-mgt-c59="" style="width: 50px;" src="{{info.foto}}">
            </div><div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%; font-size: 13px; text-align: center;">{{info.cargo}}</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-3" style="height: 100%; font-size: 13px; text-align: center;">{{info.titular}}</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-3" style="height: 100%; font-size: 13px; text-align: center;">{{info.representacion}}</div>
            <div _ngcontent-mgt-c59="" id="head" class="col-md-2" style="height: 100%; font-size: 13px; text-align: center;">{{info.asociacion}}</div>
        </div>
        <br>
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
            <p _ngcontent-mgt-c59="" style="font-weight: 600;">Comisiones de Desarrollo Territoriales</p>
            <p _ngcontent-mgt-c59="">Las dos Asociaciones de implantación territorial que componen el GDR, ADIMMAC y SEDECUCE, se configuran, para la gestión del Programa Territorial, como Comisiones de Desarrollo Territoriales. Las Comisiones de Desarrollo de cada uno de los dos territorios estarán integradas por los miembros de la Junta Directiva de las dos Asociaciones, 
                elegidos según lo establezcan sus respectivos estatutos.</p>
            <p _ngcontent-mgt-c59="">Las funciones de estas Comisiones de Desarrollo Territoriales serán, entre otras:</p>
            <ul _ngcontent-mgt-c59=""><li _ngcontent-mgt-c59="">Elaborar, aprobar y elevar a la Junta Directiva de ADESIMAN proyectos de actuación de incidencia comarcal en base al conocimiento profundo del territorio.</li><li _ngcontent-mgt-c59="">Estudio, preparación y selección de los proyectos de inversión presentados en el marco del Programa Territorial para su posterior traslado a la Junta Directiva de ADESIMAN.</li></ul>
        </div>
    </div>
</div>
<app-footer></app-footer>

