<div class="container-fluid" style="background:#324760;display:flex;align-items: center;justify-content: center;">
    <div class="row" style="width:65%">
           

                <div class="col-lg-6 col-md-6" style="display: flex;align-items: center;justify-content: center;" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                    <img src = "https://adiman.es/wp-content/uploads/2018/06/logo2.jpg"/>
                </div>
                <div class="col-lg-6 col-md-6" style="display: flex;align-items: center;justify-content: center;" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="800">
                    <img src = "../../../../assets/Adesiman.jpg" style="width: 200px;"/>
                </div>
                
</div>
