import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Compare } from 'src/app/models/compare';
import { Resultado } from 'src/app/models/resultado';
import { AuthService } from 'src/app/services/auth.service';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-examen',
  templateUrl: './examen.component.html',
  styleUrls: ['./examen.component.css']
})
export class ExamenComponent implements OnInit {

  constructor(public crud: CrudsService, public auth: AuthService) { }
  id: string = window.location.toString();
  ex: string = "";
  examenes: Array<any> = [];
  preguntas: Array<any> = [];
  preguntasexamen: Array<any> = [];
  contador: any = 0;
  paginas: any = 1;
  idExamen: string;
  idCurso: string;
  ngOnInit(): void {
    
    var cad = this.reverseString(this.id)
    var id = this.obtainId(cad)
    var defId = this.reverseString(id)
    this.idExamen = defId
    this.idExamen = this.idExamen.replace('%20', ' ');

    var idTemp = this.obtainId(this.idSinID)
    this.idCurso = this.reverseString(idTemp)
    
    

    this.crud.getExamenes().subscribe((res: any) => {
      this.findExamsbyId(res, this.id)
    })
    
  }

  //102%nemaxE/ocitamilc-oibmac/

  

  idSinID: string;
  obtainId(data: string){
    var id = ""
    for(var i = 0; i < data.length; i++){
      if(data[i] != "/"){
        id = id + data[i]
      }else{
        break;
      }
    }

    this.idSinID = data.replace(new RegExp(id.concat("/"), "g"), "")
    return id;
  }

   reverseString(str) {
    let arrStr = str.split("");
    return arrStr.reverse().join("");
  }

  empezarExamen(){
    document.getElementById("contenedor1").style.display = "none";
    document.getElementById("contenedor2").style.display = "block";
  }

  findExamsbyId(res: any, id: string){
    for(var i = 0; i < res.length; i++){
      if(res[i].nombre == this.idExamen){
        this.preguntas = res[i].preguntas
        this.preguntasexamen = res[i].preguntas[0]
      }
    } 
    
  }

  selectedResultado: string;
  listaResults: Compare[] = new Array<Compare>();
  resultado: Compare = new Compare();
  listallena: boolean = true;
  resultadoExamen: string;
  contadorCorrectos: number = 0;
  contadorIncorrectos: number = 0;
  notaExamen: number = 0;
  next(){
    var errorPreguntas = document.getElementById("errorPreguntas")
    if(this.selectedResultado != undefined){

      this.resultado = new Compare()
      this.resultado.pregunta = this.preguntas[this.contador].p
      this.resultado.respuesta = this.selectedResultado
      this.resultado.respuestaExamen = this.preguntas[this.contador].respuesta

      if(this.resultado.respuesta.toLowerCase() == this.resultado.respuestaExamen.toLowerCase()){
        this.resultado.correcto = 'true'
      }else{
        this.resultado.correcto = 'false'
      }

      this.listaResults.push(this.resultado)

      this.selectedResultado = undefined
      errorPreguntas.innerHTML = ""
      errorPreguntas.style.padding = '0'
      this.clearRadioButtons()

      if(this.contador >= 0 && this.contador < this.preguntas.length - 1){
        this.paginas = this.paginas + 1;
        this.contador = this.contador + 1;
        this.preguntasexamen = this.preguntas[this.contador];
      }else{      
        document.getElementById("contenedor2").style.display = "none"
        document.getElementById("container3").style.display = "block"

        this.listaResults.forEach(element => {
          if(element.correcto == "true"){
            this.contadorCorrectos = this.contadorCorrectos + 1
          }else{
            this.contadorIncorrectos = this.contadorIncorrectos + 1;
          }
        });
        
        var aprobadoMinimo = this.preguntas.length / 2
        if(this.contadorCorrectos >= aprobadoMinimo){
          document.getElementById("resultadoExamen").innerHTML = "Enhorabuena! Has aprobado el examen con un total de " + this.contadorCorrectos + " preguntas correctas"
          this.notaExamen = this.contadorCorrectos;
          var res = new Resultado();
          res.alumno = this.auth.globalemail
          res.examen = this.idExamen
          res.resultado = this.notaExamen
          res.curso = this.idCurso
          res.fecha = new Date().toISOString().substring(0, 10);

          this.crud.insertResultado(res)
        }else{
          document.getElementById("resultadoExamen").innerHTML = "Lo sentimos! Has suspendido el examen con un total de " + this.contadorIncorrectos + " preguntas incorrectas"
          this.notaExamen = this.contadorCorrectos;
          var res = new Resultado();
          res.alumno = this.auth.globalemail
          res.examen = this.idExamen
          res.resultado = this.notaExamen
          res.curso = this.idCurso
          res.fecha = new Date().toISOString().substring(0, 10);

          this.crud.insertResultado(res)
        }
      }
    }else{
      errorPreguntas.innerHTML = "Debes selccionar una respuesta entre las 4"
      errorPreguntas.style.padding = '1%'
    }
  }

  resultsExamen: string[] = new Array<string>();
  checkRadio(data: string){
    this.selectedResultado = data
  }

  clearRadioButtons(){
    const a = document.getElementById('a') as HTMLInputElement;
    const b = document.getElementById('b') as HTMLInputElement;
    const c = document.getElementById('c') as HTMLInputElement;
    const d = document.getElementById('d') as HTMLInputElement;
    a.checked = false
    b.checked = false
    c.checked = false
    d.checked = false
  }

}
