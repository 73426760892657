<div class="container-fluid" style="background:#E1F1F5;min-height: 100%;padding:0;">
       
    <app-header-admin></app-header-admin>
    <div style="padding-left: 5%;padding-right: 5%;padding-top: 5%;">  
    <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
    <p style="font-size: 20px;">Menu para añadir o editar servicios</p>
    
    <div class="container-fluid" style="margin:0;padding:0">
        <div class="row" >
            <div class="col-lg-4" >
                <form #servicioForm="ngForm" (ngSubmit)="onSubmit(servicioForm)">
                    <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectServicio.$key">
                    <!-- AQUI SE ESCRIBE EL TITULO -->
                    <input name="nombre" 
                    type="text" 
                    class="inputclass" 
                    #name="ngModel" 
                    [(ngModel)]="crudsService.selectServicio.nombre"
                    placeholder="Nombre servicio"
                    >
                    
                    <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                        <div class="label" fxFlex="30">PDF</div>
                        <div fxFlex="30">
                            <input type="file" name="file" (change)="onFileSelect($event)"/>
                            <div (click)="uploadPDF()" id = "botonCargar">
                                Cargar
                            </div>
                        </div>
                        <div fxFlex="30">
                          
                          <div class="label" fxFlex="30" *ngIf="completed">Archivo cargado</div>
                        </div>
                      </section>

                      <input name="direccion" 
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="crudsService.selectServicio.direccion"
                      placeholder="Direccion del pdf"
                      >
                    <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                        Agregar
                    </button>
                    <button class="btn btn-secondary" type="reset"(click)="resetForm(servicioForm)">
                        Limpiar
                    </button>

                </form>
            </div>
            <div class="col-lg-6">
                <app-lista-servicios></app-lista-servicios>
            </div>
        </div>
    </div>

    <br><br>

    <p style="font-size: 20px;">Menu para añadir o editar perfiles contratados</p>
    
    <div class="container-fluid" style="margin:0;padding:0">
        <div class="row" >
            <div class="col-lg-4" >
                <form #contratadosForm="ngForm" (ngSubmit)="onSubmitContratados(contratadosForm)">
                    <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectContratado.$key">
                    <!-- AQUI SE ESCRIBE EL TITULO -->
                    <input name="nombre" 
                    type="text" 
                    class="inputclass" 
                    #name="ngModel" 
                    [(ngModel)]="crudsService.selectContratado.nombre"
                    placeholder="Nombre servicio"
                    >
                    
                    <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                        <div class="label" fxFlex="30">PDF</div>
                        <div fxFlex="30">
                            <input type="file" name="file" (change)="onFileSelect($event)"/>
                            <div (click)="uploadPDFContratados()" id = "botonCargar">
                                Cargar
                            </div>
                        </div>
                        <div fxFlex="30">
                          
                          <div class="label" fxFlex="30" *ngIf="completedC">Archivo cargado</div>
                        </div>
                      </section>

                      <input name="direccion" 
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="crudsService.selectContratado.direccion"
                      placeholder="Direccion del pdf"
                      >
                    <button style="margin-right: 1em;" class="btn btn-primary" type="submit">
                        Agregar
                    </button>
                    <button class="btn btn-secondary" type="reset"(click)="resetForm(contratadosForm)">
                        Limpiar
                    </button>

                </form>
            </div>
            <div class="col-lg-6">
               <app-lista-perfil></app-lista-perfil>
            </div>
        </div>
    </div>
    

</div>
