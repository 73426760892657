<app-header></app-header>
<div class="container-fluid" id = "containerImage">
    <div id = "contDegradado">
        <div class = "container" style="padding-top: 4%;color:white;padding-left: 0;">
            <div class = "row" style="font-size: 20px;">
                <div class = "col-lg-12"  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
                    <span style="font-size:70px">Proyecto RED CREA 2</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" >
    <div class="row" style="padding-top:5%;" >
        <p style="font-size: 20px;font-weight: 600;">Proyecto RED CREA 2</p>
    </div>
</div>
<br>
<div  data-aos="fade-up" data-aos-delay="300" data-aos-duration="600" class="container" style="margin-bottom:3%;background-image: url('/assets/folleto.jpg');height:65%;background-repeat: no-repeat;background-size: 100% 100%;">
  
</div>
<div class="container" style="margin-bottom:3%;height:35%;background:whitesmoke;display: flex;align-items: center;justify-content: center;" >
    <div style="height:55%;width:85%;background-image: url('/assets/folleto2.jpg');background-repeat: no-repeat;background-size: 100% 100%;"></div>
</div>
<div class="container" style="height:5%;margin-bottom:3%;">
    <div class="row">
        <div style="width: 100%;text-align: center;">
            <span>Más información: </span><a style="color:#5256B3" class="linkClass" (click)="goTo('http://www.cuencacrea.com/')">www.cuencacrea.com</a>
            <p><a style="color:#5256B3" class="linkClass" (click)="goTo('https://adesiman.org/otrosprogramas/revistacrea.pdf')">Impacto del proyecto CREA I (2008/2010) en la comarca de la Sierra Media y la Mancha Alta de Cuenca</a></p>
        </div>
    </div>
</div>
<app-footer></app-footer>
