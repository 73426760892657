<table class="table table-sm table-hover">
            
    <tbody>
        <div class = "col-lg-12">
            
            <span style="font-size: 20px;">Lista de Integrantes</span><span style="float: right;">Integrantes totales: {{totalItems}}</span>
            <div id = "content" style="overflow-y: scroll;max-height:600px;width: 100%;">
                <div id = "divcontent" *ngFor="let junta of juntaList">
                    <td style="">
                        <span>Cargo del integrante</span> - {{junta.cargo}} <br> 
                        <span> Representacion </span> - {{junta.representacion}} <br>
                        <span> Titular </span>- {{junta.titular}}<br>
                        <span> Asociacion </span>- {{junta.asociacion}}<br>
                        <span> Foto </span>- {{junta.foto}}
                    </td>
                    
                    <button (click)="onEdit(junta)" style="margin-right: 1%;" class = "btn btn-secondary text-white"><i class="far fa-edit"></i></button>
                    <button (click)="onDelete(junta.$key, junta.titular)" class = "btn btn-danger text-white"><i class="far fa-trash-alt"></i></button>
                    
                </div>
            </div>
            <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>


        </div>
        
    </tbody>
</table>
