import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { Noticia } from 'src/app/models/noticia';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-usuarios',
  templateUrl: './menu-usuarios.component.html',
  styleUrls: ['./menu-usuarios.component.css']
})
export class MenuUsuariosComponent implements OnInit {

  constructor(private storage: AngularFireStorage, public crudService: CrudsService) { }

  ngOnInit(): void {
    this.crudService.selectNoticia = new Noticia()
  }

  completed: boolean;

  file: File;
  onFileSelect(event) {
   if (event.target.files.length > 0) {
     this.file = event.target.files[0];
   }
 }

 uploadPDF() {
  this.completed = false;
  const filePath = this.file.name;
  // Crea una referencia de acceso
  const fileRef = this.storage.ref(filePath);
  fileRef.put(this.file).then(() => {
    this.completed = true;
    var url = fileRef.getDownloadURL().toPromise().then((url) => {
      this.crudService.selectNoticia.url = url
    })
  });
}

uploadImage() {
  this.completed = false;
  const filePath = this.file.name;
  // Crea una referencia de acceso
  const fileRef = this.storage.ref(filePath);
  fileRef.put(this.file).then(() => {
    this.completed = true;
    var url = fileRef.getDownloadURL().toPromise().then((url) => {
      this.crudService.selectNoticia.imagen = url
    })
  });  
}


  onSubmit(noticiasForm: NgForm){
    if(noticiasForm.value.$key == null)
      this.crudService.insertNoticia(noticiasForm.value);
    else
      this.crudService.updateNoticia(noticiasForm.value);
      this.resetForm(noticiasForm);
  }
  resetForm(noticiasForm?: NgForm){
    if(noticiasForm != null){
      noticiasForm.reset();
      this.crudService.selectNoticia = new Noticia();
    }
  }

}
