import { Component, OnInit } from '@angular/core';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-proyecto14',
  templateUrl: './proyecto14.component.html',
  styleUrls: ['./proyecto14.component.css']
})
export class Proyecto14Component implements OnInit {

  constructor(private crudsService: CrudsService) { }
  public servicios: any[];
  ngOnInit(): void {

    this.crudsService.getProyectoCatorce().subscribe((res: any) => {
      this.servicios = res
    })
  }

  goTo(data: any){
    window.open(data)
  }
}
