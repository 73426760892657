import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { Contratado } from 'src/app/models/contratado';
import { Servicio } from 'src/app/models/servicio';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-menu-servicios-perfil',
  templateUrl: './menu-servicios-perfil.component.html',
  styleUrls: ['./menu-servicios-perfil.component.css']
})
export class MenuServiciosPerfilComponent implements OnInit {

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }
  public completed: boolean
  public completedC: boolean
  ngOnInit(): void {
  }

  file: File;
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

  onSubmit(servicioForm: NgForm){
    if(servicioForm.value.$key == null)
      this.crudsService.insertServicio(servicioForm.value);
    else
      this.crudsService.updateServicio(servicioForm.value);
      this.resetForm(servicioForm);
  }
  resetForm(servicioForm?: NgForm){
    if(servicioForm != null){
      servicioForm.reset();
      this.crudsService.selectServicio = new Servicio();
    }
  }





  uploadPDF() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectServicio.direccion = url
      })
    });
    

    
    
  }

  uploadPDFContratados() {
    this.completedC = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completedC = true;
      console.log(this.file.name)
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectContratado.direccion = url
      })
    });
    

    
    
  }



  
  onSubmitContratados(contratadosForm: NgForm){
    if(contratadosForm.value.$key == null)
      this.crudsService.insertContratado(contratadosForm.value);
    else
      this.crudsService.updateContratado(contratadosForm.value);
      this.resetForm(contratadosForm);
  }
  resetFormContratados(contratadosForm?: NgForm){
    if(contratadosForm != null){
      contratadosForm.reset();
      this.crudsService.selectServicio = new Contratado();
    }
  }



}
