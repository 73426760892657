import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Noticia } from 'src/app/models/noticia';
import { CrudsService } from 'src/app/services/cruds.service';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.css']
})
export class PruebasComponent implements OnInit {

 file: File;
 onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
  }
}

  constructor(public crudsService: CrudsService, private storage: AngularFireStorage) { }
  completed;
  ngOnInit(): void {
    this.crudsService.selectNoticia = new Noticia()
  }

  

  uploadImage() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectNoticia.imagen = url
      })
    });  
  }

  uploadPDF() {
    this.completed = false;
    const filePath = this.file.name;
    // Crea una referencia de acceso
    const fileRef = this.storage.ref(filePath);
    fileRef.put(this.file).then(() => {
      this.completed = true;
      var url = fileRef.getDownloadURL().toPromise().then((url) => {
        this.crudsService.selectNoticia.url = url
      })
    });
    

    
    
  }



  //Sube el archivo a Cloud Storage

  onSubmit(noticiasForm: NgForm){
    if(noticiasForm.value.$key == null)
      this.crudsService.insertNoticia(noticiasForm.value);
    else
      this.crudsService.updateNoticia(noticiasForm.value);
      this.resetForm(noticiasForm);
  }
  resetForm(noticiasForm?: NgForm){
    if(noticiasForm != null){
      noticiasForm.reset();
      this.crudsService.selectNoticia = new Noticia();
    }
  }
  

}
