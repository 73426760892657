import { Leccion } from "./leccion";
import { Tarea } from "./tarea";

export class Curso {
    $key: string;
    agenda: Tarea[];
    emailprofesor: string;
    id:string;
    imagen: string;
    lecciones: Leccion[];
    nombreprofesor: string;
    tareas: Tarea[];
}
