import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  data: string = "hola"
  constructor(public auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.auth.observer();
    
  }
  showSlide(){
    document.getElementById("slide").style.display = "block";
    document.getElementById("slide").className = "animate__animated animate__fadeInLeft";
    document.getElementById("bars").className = "fas fa-times";
    document.getElementById("bars").style.position = "fixed";
    document.getElementById("bars").style.border = "3px solid white";
    document.getElementById("bars").style.padding = "0.5em";
    
  }
  closeMenu(){
    var clas = document.getElementById("bars").className;
    if(clas == "fas fa-times"){
      document.getElementById("slide").className = "animate__animated animate__fadeOutLeft";
      document.getElementById("bars").className = "fas fa-bars";
      document.getElementById("bars").style.position = "absolute";
      document.getElementById("bars").style.border = "3px solid #233142";
      document.getElementById("bars").style.padding = "0.5em";
    }
  }
  dewBorder(){
    document.getElementById("dropdownMenuButton").style.border = "0";
  }
  closeLogin(){
    document.getElementById("contenedorLogin").style.display = "none"
  }
  
  showLogin(data?: any){
    document.getElementById("gastly").textContent = data;
    var cont = document.getElementById("contenedorLogin");
    cont.style.display = "block";
    cont.className = "container-fluid animate__animated animate__fadeInUpBig"
    var interCont = document.getElementById("interCont");
    interCont.className = "container animate__animated animate__fadeIn animate__delay-1s"
  }

  forCourses(goTo: string){
    if(this.auth.globalemail == ''){
      this.showLogin(goTo);
    }else{
      this.router.navigate(['formacion/'+ goTo]);
    }
  }

  goTo(data: any){
    window.open(data)
  }


}
