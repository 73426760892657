import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ofertas-de-trabajo',
  templateUrl: './ofertas-de-trabajo.component.html',
  styleUrls: ['./ofertas-de-trabajo.component.css']
})
export class OfertasDeTrabajoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
