import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Junta } from 'src/app/models/junta';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-junta',
  templateUrl: './lista-junta.component.html',
  styleUrls: ['./lista-junta.component.css']
})
export class ListaJuntaComponent implements OnInit {
  totalItems: number;
  juntaList: Junta[];
  lista: string[];

  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.juntaList = [];
    
    this.crudsService.getJuntaO()
    .snapshotChanges()
    .subscribe(item =>{
      this.juntaList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.juntaList.push(x as Junta);
      });
      this.totalItems = this.juntaList.length;
    })
  }

  onEdit(junta: Junta){
    this.crudsService.selectJunta = Object.assign({},junta)
  }
  onDelete($key: string, titular: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar la noticia '+ titular + "?",
        accept: () => {
          this.crudsService.deleteJunta($key);
        }
    });
    
  }

}
