

        
        <table class="table table-sm table-hover" style="background:white">
            
            <tbody>
                <div class = "col-lg-12">
                    
                    <span style="font-size: 20px;">Lista de ofertas</span><span style="float: right;">servicios totales: {{totalItems}}</span>
                    <div id = "content" style="overflow-y: scroll;max-height:200px;width: 100%;">
                        <div id = "divcontent" *ngFor="let oferta of ofertasList">
                            <td style="">
                                <span>Nombre de la oferta</span> - {{oferta.nombre}} <br> 
                                <span> Direccion de la imagen </span> - {{oferta.direccion}} <br>
                                
                            </td>
                            
                            <button (click)="onEdit(oferta)" style="margin-right: 1%;" class = "btn btn-secondary text-white"><i class="far fa-edit"></i></button>
                            <button (click)="onDelete(oferta.$key, oferta.nombre)" class = "btn btn-danger text-white"><i class="far fa-trash-alt"></i></button>
                            
                        </div>
                    </div>
                    <p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>


                </div>
                
            </tbody>
        </table>
       
  