

<!-- ESTE ES EL MENU DE NOTICIAS -->
    
    <div class="container-fluid" style="background:#E1F1F5;min-height: 100%;padding:0;">

            <app-header-admin></app-header-admin>
            <div style="padding-left: 5%;padding-right: 5%;padding-top: 2%;">  
            <h3>PRUEBAS DE MENUS CRUD INTEGRADOS</h3>
            <p style="font-size: 20px;">Menu para añadir o editar cursos</p>
            <div class="row">
                <div class="col-12" style="background: white;">
                    
                    <app-lista-cursos (propagar)="procesaPropagar($event)"></app-lista-cursos>
                </div>

                <div class="col-lg-4 col-md-12" style="padding-top: 1%; background: whitesmoke; margin-top: 3em; margin-bottom: 3em;">
                    <form #cursosForm="ngForm" (ngSubmit)="onSubmit(cursosForm)">
                        <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="crudsService.selectCurso.$key">
                        <div  class="row" >
                            <div  class="col-md-11">
                                <input name="id" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectCurso.id"
                                placeholder="Titulo del curso"
                                >
                            </div>
                            
                            <div  class="col-md-11">
                                <input name="emailprofesor" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectCurso.emailprofesor"
                                placeholder="Email del profesor"
                                >
                            </div>
                            <div  class="col-md-11">
                                <input name="nombreprofesor" 
                                type="text" 
                                class="inputclass" 
                                #name="ngModel" 
                                [(ngModel)]="crudsService.selectCurso.nombreprofesor"
                                placeholder="Nombre del profesor"
                                >
                                
                                <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                                    <div class="label" fxFlex="30" style="font-weight: bold;">IMAGEN</div>
                                    <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/>
                                      <div style = "background:blue;max-width: 5em;color:white;text-align: center;margin-top: 0.5em;margin-bottom: 0.5em;cursor:pointer" mat-flat-button color="primary" (click)="uploadImage()">Cargar</div></div>
                                    <div fxFlex="30">
                                      
                                      <div class="label" fxFlex="30" *ngIf="completed">Archivo cargado</div>
                                    </div>
                                  </section>
          
                                  
                                  <input name="imagen" 
                                  id = "imagen"
                                  type="text" 
                                  class="inputclass" 
                                  #name="ngModel" 
                                  [(ngModel)]="crudsService.selectCurso.imagen"
                                  placeholder="Imagen del curso"
                                  >
                            </div>
                        </div>
                        <button  type="submit" class="btn btn-primary" style="margin-right: 1em;"> Agregar </button>
                        <button (click)="reset()"  type="reset" class="btn btn-secondary"> Limpiar </button>
                        <br>
                        <p id = "mensajeCursos" style="background-color: green;margin-top:0.5em;color:white;max-width: 20em;text-align: center;"></p>
                    </form>
                </div>

                <div class="col-lg-4 col-md-12" style="padding-top: 1%; background: whitesmoke; margin-top: 3em; margin-bottom: 3em;">

                 
                    <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                      <div class="label" fxFlex="30" style="font-weight: bold;">AGENDA</div>
                      <p>Pdfs de la guia del curso</p>
                      <div style="background: white;margin-top: 0.1em;max-width: 10em;text-align: center;" *ngFor="let tarea of arrayg">{{tarea.nombre}} <i (click) = "eliminarPdfGuia(tarea)" class="far fa-trash-alt ng-star-inserted" style="cursor: pointer;" aria-hidden="true"></i></div>
                      <br>
                      <p>Añade Pdfs a la guía</p>

                      <input name="nombre" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.nuevopdfguia"
                      placeholder="Nombre del pdf"
                      >

                      <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/>
                        <div style = "background:blue;max-width: 5em;color:white;text-align: center;margin-top: 0.5em;margin-bottom: 0.5em;cursor:pointer" mat-flat-button color="primary" (click)="uploadPDFAgenda()">Cargar</div></div>
                      <div fxFlex="30">
                        
                        <div class="label" fxFlex="30" *ngIf="agendaCompleta">Archivo cargado</div>
                      </div>
                    </section>

                    
                    <input name="agenda" 
                    id = "agenda"
                    type="text" 
                    class="inputclass" 
                    #name="ngModel" 
                    [(ngModel)]="this.pdfguia"
                    placeholder="Agenda del curso (PDF)"
                    >

                    <div (click)="anadirPdfGuia()" style="background:blue;max-width: 8em;text-align: center;color: white;cursor: pointer;"> Añadir pdf</div>
                    <br>
                    <p style="background: rgb(105, 32, 32);color:white;font-size:13px" id = "errorpdf"></p>
                    <p style="background: rgb(45, 129, 52);color:white;font-size:13px" id = "successpdf"></p>
              
                    <div class="label" fxFlex="30" style="font-weight: bold;">TAREAS</div>
                    <p>Tareas del curso</p>
                    <div style="background: white;margin-top: 0.1em;max-width: 10em;text-align: center;" *ngFor="let tarea of array">{{tarea.nombre}} <i (click) = "eliminarTarea(tarea)" class="far fa-trash-alt ng-star-inserted" style="cursor: pointer;" aria-hidden="true"></i></div>
                    <br>
                    <p>Añade tareas al curso</p>

                    <input name="nombre" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.nuevatarea"
                      placeholder="Nombre de la tarea"
                      >

                    <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                        <div class="label" fxFlex="30">PDF</div>
                        <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/><button style="cursor:pointer" mat-flat-button color="primary" (click)="uploadPDF()">Cargar</button></div>
                        <div fxFlex="30">
                          
                          <div class="label" fxFlex="30" *ngIf="tareacompleted">Archivo cargado</div>
                        </div>
                      </section>

                      
                      <input name="pdf" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.pdf"
                      placeholder="Direccion del PDF"
                      >

                      <div (click)="anadirTarea()" style="background:blue;max-width: 8em;text-align: center;color: white;cursor: pointer;"> Añadir tarea</div>
                      <br>
                      <p style="background: rgb(105, 32, 32);color:white;font-size:13px" id = "errortareas"></p>
                      <p style="background: rgb(45, 129, 52);color:white;font-size:13px" id = "successtareas"></p>
                </div>
                
                    

                <div class="col-lg-4 col-md-12" style="padding-top: 1%; background: whitesmoke; margin-top: 3em; margin-bottom: 3em;">
                  <div class="label" fxFlex="30" style="font-weight: bold;">LECCIONES</div>
                    <p>Lecciones del curso</p>
                    <div style="background: white;margin-top: 0.1em;max-width: 10em;text-align: center;" *ngFor="let leccion of arrayL">{{leccion.nombre}} <i (click) = "eliminarLeccion(leccion)" class="far fa-trash-alt ng-star-inserted" style="cursor: pointer;" aria-hidden="true"></i></div>
                    <br>
                    <p>Añade lecciones al curso</p>

                    <input name="nombre" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.nuevaleccion"
                      placeholder="Nombre de la leccion"
                      >

                    <section fxLayout="column" fxLayoutAlign="space-around center" fxFlex="100" fxLayoutGap="20px">
                        <div class="label" fxFlex="30">PDF</div>
                        <div fxFlex="30"><input type="file" name="file" (change)="onFileSelect($event)"/><button style="cursor:pointer" mat-flat-button color="primary" (click)="uploadPDFL()">Cargar</button></div>
                        <div fxFlex="30">
                          
                          <div class="label" fxFlex="30" *ngIf="leccioncompleted">Archivo cargado</div>
                        </div>
                      </section>

                      
                      <input name="pdf" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.pdfleccion"
                      placeholder="Direccion del PDF"
                      >

                      <input name="video" 
                      id = "imagen"
                      type="text" 
                      class="inputclass" 
                      #name="ngModel" 
                      [(ngModel)]="this.video"
                      placeholder="Video para la leccion"
                      >

                      <div (click)="anadirLeccion()" style="background:blue;max-width: 8em;text-align: center;color: white;cursor: pointer;"> Añadir leccion</div>
                      <br>
                      <p style="background: rgb(105, 32, 32);color:white;font-size:13px" id = "errorleccion"></p>
                      <p style="background: rgb(45, 129, 52);color:white;font-size:13px" id = "successleccion"></p>
                </div>
                
            </div>
        </div>
        
    </div>