import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Oferta } from 'src/app/models/oferta';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-ofertas-lista',
  templateUrl: './ofertas-lista.component.html',
  styleUrls: ['./ofertas-lista.component.css']
})
export class OfertasListaComponent implements OnInit {

  totalItems: number;
  ofertasList: Oferta[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.ofertasList = [];
    
    this.crudsService.getOfertasO()
    .snapshotChanges()
    .subscribe(item =>{
      this.ofertasList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.ofertasList.push(x as Oferta);
      });
      this.totalItems = this.ofertasList.length;
      
    })

  }

  onEdit(oferta: Oferta){
    this.crudsService.selectOferta = Object.assign({},oferta)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar la oferta '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteOferta($key);
        }
    });
    
  }
}
