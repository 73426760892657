import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Pueblo } from 'src/app/models/pueblo';
import { CrudsService } from 'src/app/services/cruds.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationService } from 'primeng/api';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-listapueblos',
  templateUrl: './listapueblos.component.html',
  styleUrls: ['./listapueblos.component.css']
})
export class ListapueblosComponent implements OnInit {
  totalItems: number;
  pueblosList: Pueblo[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {
   this.crudsService.getPueblosO().snapshotChanges().subscribe(item => {
     this.pueblosList = [];
     item.forEach(element => {
       let x = element.payload.toJSON();
       x["$key"] = element.key;
       this.pueblosList.push(x as Pueblo)
     })
     this.totalItems = this.pueblosList.length;
   })
  }
  onEdit(pueblo: Pueblo){
    this.crudsService.selectPueblo = Object.assign({},pueblo)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el pueblo '+ titulo + "?",
        accept: () => {
          this.crudsService.deletePueblo($key);
        }
    });
    
  }
}
