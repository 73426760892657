import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-proyecto-proder',
  templateUrl: './lista-proyecto-proder.component.html',
  styleUrls: ['./lista-proyecto-proder.component.css']
})
export class ListaProyectoProderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
