import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ProyectoVtres } from 'src/app/models/proyectovtres';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-proyecto-vtres',
  templateUrl: './lista-proyecto-vtres.component.html',
  styleUrls: ['./lista-proyecto-vtres.component.css']
})
export class ListaProyectoVtresComponent implements OnInit {



  totalItems: number;
  proyectovtresList: ProyectoVtres[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.proyectovtresList = [];
    
    this.crudsService.getProyectoVtresO()
    .snapshotChanges()
    .subscribe(item =>{
      this.proyectovtresList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.proyectovtresList.push(x as ProyectoVtres);
      });
      this.totalItems = this.proyectovtresList.length;
      
    })

  }

  onEdit(proyecto: ProyectoVtres){
    this.crudsService.selectProyectoVtres = Object.assign({},proyecto)
  }
  onDelete($key: string, titulo: any){
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el curso '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteProyectoVtres($key);
        }
    });
    
  }

}
