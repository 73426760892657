<app-header></app-header>
<div class="container-fluid" style="padding:1%;background:whitesmoke">
  <div class="container" style="text-align: center;">
    <p>Categoria: Noticias</p>
  </div>
  
</div>
<br>
<div class="container" data-aos="fade-in" data-aos-delay="300" data-aos-duration="900">
  <div class="row" style="margin-bottom: 5em;">
    <div *ngFor = "let noticia of this.imagenes" class="col-lg-4 col-md-6" style="height:23em;max-width: 400px;">
      <div id = "shadow" (click)="goTo(noticia.url)" style="width:100%;height:95%;background:#394F69;text-align: center;">
        <div style="height:85%;background-image: url('{{noticia.imagen}}');width:100%;background-size: 100% 150%;"></div>
        <p style="margin-top: 3%;color:white">{{noticia.titulo}}</p>
       
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>