import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ProyectoSiete } from 'src/app/models/proyectosiete';
import { CrudsService } from 'src/app/services/cruds.service';

@Component({
  selector: 'app-lista-proyecto-siete',
  templateUrl: './lista-proyecto-siete.component.html',
  styleUrls: ['./lista-proyecto-siete.component.css']
})
export class ListaProyectoSieteComponent implements OnInit {

  totalItems: number;
  proyectosieteList: ProyectoSiete[];
  lista: string[];
  constructor(private crudsService: CrudsService, public confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.proyectosieteList = [];
    
    this.crudsService.getProyectoSieteO()
    .snapshotChanges()
    .subscribe(item =>{
      this.proyectosieteList = [];
      item.forEach(element => {
        let x = element.payload.toJSON();
        x["$key"] = element.key;
        this.proyectosieteList.push(x as ProyectoSiete);
      });
      this.totalItems = this.proyectosieteList.length;
      
    })

  }

  onEdit(proyecto: ProyectoSiete){
    this.crudsService.selectProyectoSiete = Object.assign({},proyecto)
  }
  onDelete($key: string, titulo: any){
    
    this.confirmationService.confirm({
        message: 'Estas seguro de que quieres eliminar el servicio '+ titulo + "?",
        accept: () => {
          this.crudsService.deleteProyectoSiete($key);
        }
    });
    
  }

}
