import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { JsonserviceService } from 'src/app/services/jsonservice.service';

@Component({
  selector: 'app-pueblos',
  templateUrl: './pueblos.component.html',
  styleUrls: ['./pueblos.component.css']
})
export class PueblosComponent implements OnInit {

  constructor(private json: JsonserviceService) { }
  pueblos: any = [];

  @ViewChild('mybtn') myBtn:ElementRef;
  ngOnInit(): void {
    this.json.getJson('/assets/pueblos.json').subscribe((res: any) => {
      for(var i = 0; i < res.length; i++){
        this.pueblos[i] = res[i].nombre;
      }
    });
    
    
    
  }
  intoPopUp(data: string){
    var nombre = data;
    document.getElementById("sliderPueblo").style.visibility = "visible";
    document.getElementById("sliderPueblo").className = "animate__animated animate__fadeIn animate__faster"
    document.getElementById("puebloname").innerHTML = nombre;
    this.neededData(this.json, nombre);
  }
  closePopUp(){
    document.getElementById("sliderPueblo").style.visibility = "hidden";
    document.getElementById("sliderPueblo").className = ""
  }
  neededData(json: JsonserviceService, data: string){
    var imgs = "";
    this.json.getJson('/assets/pueblos.json').subscribe((res: any) => {
      for(var i = 0; i < res.length; i++){
        if(res[i].nombre == data){
          imgs = res[i].imagenes[0];
          document.getElementById("pueblodesc").innerHTML = res[i].desc;
          document.getElementById("puebloinfo").innerHTML = res[i].info;
          document.getElementById("imgs").style.backgroundImage = "url('"+imgs+"')";
          document.getElementById("imgs").style.backgroundSize = "100% 100%"
          document.getElementById("imgs").style.marginBottom = "2em"
        }
      }
      
    });
  }

  

}
